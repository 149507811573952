/* Progress Page Styling */
.progress-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    background-color: #ffffff;
  }

  .progress-page h1 {
    font-size: x-large;
    justify-content: center;
    text-align: center;
    padding-bottom: 10vw;
  }

  .progress-page h2 {
    font-size: large;
    justify-content: center;
    text-align: center;
  }
  
  .progress-status {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .progress-container {
    width: 70%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #3db4ad;
    transition: width 0.3s ease-in-out;
    width: 50%;
  }
  
  .progress-percentage {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  .animation-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align horizontally */
    justify-content: center; /* Center align vertically */
    margin-top: 20px; /* Optional spacing above the container */
  }
  
  .stage-image {
    width: 150px; /* Adjust width of the Reddit image */
    margin-bottom: 0px; 
    height: 70px;/* Add spacing between the image and animation */
  }

  


/* Add media queries for responsive font scaling */
@media (max-width: 768px) {
  .progress-status,
  .progress-percentage {
      font-size: 1rem;
  }

  
}

@media (max-width: 480px) {
  .progress-status,
  .progress-percentage {
      font-size: 0.86rem; /* Reduce size further for iPhone screens */
      line-height: 1.1;  /* Adjust line height to save space */
      text-align: center;
  }

  .progress-container {
      width: 85%; /* Adjust width for smaller screens */
  }

  .progress-page {
      padding: 0 5px; /* Slightly smaller padding on very small screens */
  }

  .progress-page h1 {
    font-size: large;
    justify-content: center;
    text-align: center;
    padding-bottom: 20vw;
  }

  .progress-page h2 {
    font-size: medium;
    justify-content: center;
    text-align: center;
  }
  
  /* .stage-image {
    width: 120px; 
    height: auto;
    margin-bottom: 5px;
    margin-top: 70px;
}

.animation-container {
    margin-top: 40px; 
} */

.animation-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stage-image {
  width: 80px; /* Reduce image size */
  height: auto;
  margin-top: 15px;
}

.progress-bar-container p {
  font-size: 14px; /* Adjust text size */
  margin-bottom: 5px; /* Add space below text */
}

}