.main-content {
  background-color: #fefefe;
}

/* Container for the history page */
.history-page-container {
  width: 100%;
  margin: 0 auto;
  background-color: white;
}

.min-headline {
  font-size: 1.5em;
  text-align: left;
  color: #333;
  margin-bottom: 10px;
  padding-left: 20px;
}

.history-container {
  padding: 50px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* font-family: IBM Plex Sans; */
}

/* Header row for history section, including search bar and dropdown */
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.box-headline {
  font-size: 1.1em;
  color: #333;
}

.right-side-controls {
  display: flex;
  align-items: center;
  font-size: 14px;
}

/* Search bar */
.search-bar {
  padding: 10px;
  font-size: 0.9em;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  outline: none;
  margin-right: 10px;
}

.search-bar:focus {
  border-color: #54a2f0;
}

/* Dropdown styling */
.history-container .sort-dropdown {
  padding: 10px;
  font-size: 0.85em;
  /* border: 1px solid #ccc; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  background-color: #f9fbff;
  font-weight: bold;
  color: #3d3c42;
}

.sort-dropdown:focus {
  border-color: #54a2f0;
}

/* Styling for the history table */
.history-table-container {
  overflow-x: auto; /* Allows horizontal scrolling on smaller screens */
  margin-top: 20px;
}

.history-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders don't overlap */
  margin-bottom: 20px;
  background-color: white; /* Set table background to white */
}

.history-table th,
.history-table td {
  padding: 22px 25px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 0.9em;
}

.history-table th {
  color: #1d3557; /* Light gray color for the headings */
  font-weight: bold;
  border-top: none; /* Remove the line above the table headers */
}

.history-table td {
  color: #555;
}

.history-table tr {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.history-table tr:hover {
  background-color: #f1f1f1;
}

/* Loading state and no history message */
.history-table td[colspan="8"] {
  font-style: italic;
  color: #555;
  text-align: center;
  padding: 20px;
  background-color: #fafafa;
}

.view-prediction {
  border: none;
  background-color: transparent;
  color: #888;
  text-decoration: underline;
  padding: 7px 14px;
  border-radius: 5px;
  font-family: Arial;
  font-weight: 600;
  font-size: 0.9em;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.view-prediction:hover {
  color: #1d3557;
  cursor: pointer;
}

/* Pagination section */
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9em;
}

.pagination span {
  font-size: 14px;
  color: #555;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.page-btn {
  padding: 8px 16px;
  margin: 0 5px;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.page-btn:hover {
  background-color: #ddd;
}

.page-btn.active {
  color: #888;
}

.page-btn:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .history-container {
    padding: 15px;
  }

  .box-headline {
    font-size: 1.5em;
  }

  .history-table th,
  .history-table td {
    padding: 8px 10px;
    font-size: 0.8em;
  }

  /* .pagination-controls {
    font-size: 0.1em;
  } */

  .pagination span {
    margin-bottom: 10px;
  }
}

.history-page-container .pagination span {
  font-size: 1em;
}

@media (max-width: 768px) {
  .history-page-container .pagination span {
    font-size: 0.8em;
  }
}
