.testimonials-partnerships-section  {
  width: 100%;
}
.testimonials-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.carousel-content {
  display: flex;
  flex-direction: column; /* Ensure the testimonials are stacked vertically if you wish */
  justify-content: center;
  align-items: center;
}

.testimonial-column {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  background-color: rgba(150, 150, 150, 0.1);
  padding: 7px;
  border-radius: 20px;
}

.indicator-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd;
  margin: 0 5px;
  cursor: pointer;
}

.indicator-dot.active {
  background-color: #139C8D;
}
