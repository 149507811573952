/* MobileMenu.css */

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-color: #ffffff; /* Opaque background */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-100%); /* Hidden by default */
    transition: transform 0.3s ease-in-out;
    z-index: 1001; /* Above the header */
  }
  
  .mobile-menu.open {
    transform: translateY(0); /* Slide down to visible */
  }
  
  .mobile-menu .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    color: #343A40;
    cursor: pointer;
  }
  
  .mobile-menu .mobile-menu-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .mobile-menu .mobile-menu-links a {
    margin: 1.5em 0;
    font-size: 1.5em;
    color: #343A40;
    text-decoration: none;
    transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
  }
  
  /* Enhanced Hover Effect */
  .mobile-menu .mobile-menu-links a:hover {
    color: #139C8D !important; /* Enforce hover color */
  }
  
  /* Optional: Active Link Styling */
  .mobile-menu .mobile-menu-links a.active {
    color: #139C8D;
  }
  
  .menu {
    margin-bottom: 0; /* Remove bottom margin */
  }
  
  .analysis {
    margin-top: 0; /* Remove top margin */
    padding-top: 0; /* Remove top padding */
  }
  