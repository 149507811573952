/* Apply box-sizing to all elements */
* {
  box-sizing: border-box;
}

/* Container */
.modelSection {
  background-color: #fff;
  color: #343A40;
  font-family: 'Merriweather', serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden; /* Prevent overflow */
}

/* Row */
.searchRow {
  display: flex;
  flex-direction: column; /* Stack on mobile */
  align-items: center;
  max-width: 1600px;
  width: 100%;
  padding: 0 5%;
}

/* Left and Right Columns */
.leftColumn, .rightColumn {
  flex: 1 1 50%; /* Equal width for both columns */
  text-align: center;
  /* margin-bottom: 15vw; */
  padding: 0 5%; /* Add equal padding to both columns */
}

.modelText {
  font-weight: bold;
  font-size: 4vw; /* Use vw for responsive font size */
  line-height: 1.2;
  margin: 0;
  margin-bottom: 15vw;
}

.modelText span {
  display: block;
  white-space: nowrap;
}

.modelImg {
  width: 80vw; /* Use vw for responsive image size */
  max-width: 800px; /* Set max-width to prevent overflow */
  height: auto;
  display: block;
}

/* Desktop Styles */
@media (min-width: 992px) {
  .searchRow {
    flex-direction: row; /* Side by side */
    align-items: center;
  }
  
  .leftColumn {
    text-align: center;
    margin-bottom: 0;
  }
  
  .modelText {
    font-size: 2vw; /* Adjust the font size for smaller text on larger screens */
  }
  
  .rightColumn {
    margin-bottom: 0;
  }
  
  .modelImg {
    width: 40vw; /* Adjust for twice the size on larger screens */
    max-width: 1200px; /* Set larger max-width for desktop */
    height: auto;
    /* padding-right: 10%; Increase padding on the right */
  }
}

/* Mobile Styles */
@media (max-width: 991px) {
  /* Adjust .modelSection height to half of viewport height */
  .modelSection {
    /* min-height: 50vh; 50% of viewport height */
    
  }
  
  /* Adjust font sizes for mobile */
  .modelText {
    padding-top: 10vw;
    font-size: 3.2vw; /* Increase font size relative to smaller height */
    line-height: 4vw;
  }
  
  /* Adjust image size for mobile */
  .modelImg {
    width: 80vw; /* Increase width for better visibility */
    max-width: 400px; /* Reduce max-width to fit mobile screens */
    
  }
  
  /* Adjust padding within columns */
  .leftColumn, .rightColumn {
    padding: 0 10%; /* Increase horizontal padding for better spacing */
  }
}

@media (max-width:768px) {

  .modelSection {
    /* padding-top: 65vw; */
  }
  
}
