/* Global Box Sizing */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Main Section Styling */
.trading-summary-section {
  /* background-color: #1D3557; */
  padding: 40px 20px;
  text-align: center;
  color: #1D3557;
  font-family: 'Merriweather', serif;
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  justify-content: space-between; /* Distribute rows evenly */
}

/* Top Row: Text Styling */
.trading-text-row h3 {
  font-weight: bold;
  font-size: 2.5em;
  margin-bottom: 20px;
}

/* Bottom Row: Images Styling */
.trading-images-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px; /* Add spacing between images */
}

/* Columns for Images */
.trading-column {
  flex: 1 1 45%; /* Adjust column width for responsiveness */
  max-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Image Styling */
.trading-img {
  width: 100%;
  height: auto;
  max-width: 400px; /* Set max width for large screens */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
  border-radius: 10px; /* Rounded corners */
}

/* Responsive Design */
@media (max-width: 768px) {
  .trading-summary-section {
    padding: 20px;
  }

  .trading-text-row h3 {
    font-size: 2em;
  }

  .trading-images-row {
    flex-direction: column; /* Stack images vertically on small screens */
    gap: 20px;
  }

  .trading-column {
    flex: 1 1 100%; /* Full width for small screens */
    max-width: 100%;
  }

  .trading-img {
    width: 70%; /* Adjust image size for smaller screens */
    max-width: 300px; /* Limit image size */
  }
}
