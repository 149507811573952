/* Overlay that dims the background */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "Inter", sans-serif;
}

/* Popup container */
.popup-container {
  background-color: white;
  width: 750px;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.upgrade-popup-ribbon {
  position: absolute; /* Position relative to the plan box */
  top: 0; /* Align the ribbon at the top */
  left: 0; /* Start from the left edge */
  width: 100%; /* Span the full width of the plan box */
  background-color: #139c8d; /* Green background */
  color:#f7f9fc;
  font-weight: bold; /* Bold text for emphasis */
  text-align: center; /* Center-align the ribbon text */
  padding: 5px 0; /* Padding for better spacing */
  z-index: 1; /* Ensure it appears above other elements */
  font-size: 14px; /* Adjust font size for readability */
  border-top-left-radius: 8px; /* Match the plan box's rounded corners */
  border-top-right-radius: 8px; /* Match the plan box's rounded corners */
}

.upgrade-popup-ribbon-onetime {
  position: absolute; /* Position relative to the plan box */
  top: 0; /* Align the ribbon at the top */
  left: 0; /* Start from the left edge */
  width: 100%; /* Span the full width of the plan box */
  background-color: #6ba8a1; /* Green background */
  color:#f7f9fc;
  font-weight: bold; /* Bold text for emphasis */
  text-align: center; /* Center-align the ribbon text */
  padding: 5px 0; /* Padding for better spacing */
  z-index: 1; /* Ensure it appears above other elements */
  font-size: 14px; /* Adjust font size for readability */
  border-top-left-radius: 8px; /* Match the plan box's rounded corners */
  border-top-right-radius: 8px; /* Match the plan box's rounded corners */
}

/* .popup-container::before  {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 20px solid transparent;
    border-image: repeating-linear-gradient(
      45deg,
      rgb(167, 46, 46),
      red 20px,
      white 20px,
      white 40px
    ) 20;
  } */

.mistletoe {
  position: absolute;
  top: -17px;
  right: -32px;
  transform: rotate(242deg);
  width: 13%;
}

.popup-container p {
  text-align: center;
  margin-top: 0;
}

.popup-container h2 {
  margin-bottom: 0;
}

/* Popup header */
.popup-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.popup-header-text-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.popup-header-text {
  /* text-align: left !important; */
  margin-top: 5px !important;
}

#popup-header-text {
  align-self: center !important;
  font-style: italic;
}

.popup-header h2 {
  font-size: 25px;
  font-weight: bold;
  color: #1d3557;
  align-self: center;
  margin-bottom: 10px;
}

/* Close button for the popup */
.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Toggle switch for billing cycle */
.billing-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #1d3557;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Plan options */
.plan-options {
  display: flex;
  justify-content: space-between;
  z-index: 1500;
}

.plan {
  background-color: #f7f9fc;
  padding: 15px;
  border-radius: 8px;
  width: 30%;
  text-align: center;
  border: 2px solid transparent;
  cursor: pointer; /* Make the plan buttons clickable */
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
  position: relative;
  padding-top: 30px; /* Add padding to create space for the ribbon */
  font-family: "Inter", sans-serif;
}
.plan:hover {
  border-color: #1d3557;
}

.plan.active {
  border-color: #1d3557;
}

.plan h3 {
  font-size: 24px;
}

.plan p {
  margin: 10px 0;
}

.bold {
  font-weight: bold;
}

.plan ul {
  list-style-type: none;
  padding: 0;
}

.plan ul li {
  text-align: left;
  font-size: 13px;
}

/* Footer buttons */
.popup-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 1500;
}

.cancel-button {
  background-color: transparent;
  border: 1px solid #ccc;
  color: #333;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.cancel-button:hover {
  background-color: #f0f0f0;
}

.confirm-button {
  background-color: #139c8d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.upgrade-popup-br {
  display: none;
}

.confirm-button:hover {
  background-color: #1976d2;
}

@media (max-width: 500px) {
  .upgrade-popup-br {
    display: block !important;
  }
  .popup-header-text,
  #popup-header-text,
  .slider-text {
    font-size: 15px;
  }
  .plan-options {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .plan {
    width: 90%;
    padding: 0px 10px;
    margin: 10px 0px;
    padding-top: 25px;
  }
  .popup-container {
    height: 100vh;
    overflow: scroll;
  }
  .popup-header h2 {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
  }
  .upgrade-popup-ribbon {
    font-size: 12px; /* Reduce font size for smaller screens */
    padding: 4px 0; /* Slightly smaller padding */
    top: 0; /* Keep the ribbon at the top of the plan box */
  }
  /* .popup-container::before  {
      height: 103vh;
    }
    .mistletoe  {
      top: 0;
      right: -20px;
      width: 30%;
    } */
  .close-button {
    top: 15px !important;
    right: 15px !important;
  }
}
