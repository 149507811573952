/* Container for the search box */
.landing-search-box {
  display: flex;
  justify-content: left;  /* Aligns the input and button to the left */
  align-items: center;
  position: relative; /* To allow absolute positioning for suggestions list */
  font-family: 'Merriweather', serif; /* Apply globally */
}

.search-box {
  display: flex;
  justify-content: left;  /* Aligns the input and button to the left */
  align-items: center;
  width: 100%;
  position: relative; /* To allow absolute positioning for suggestions list */
  font-family: 'Merriweather', serif; /* Apply globally */
}

.search-box input  {
  width: 100%;
  font-family: 'Merriweather', serif; /* Apply globally */
}

/* Input styling */
.landing-ticker-input {
  width: 100%; /* Make the input take the full width of the container */
  border-radius: 7.5px;  /* Rounded corners */
  border: 2px solid rgba(29, 41, 61, 0.2); /* Border styling */
  color: rgba(29, 41, 61, 0.65);
  font-size: 15px; /* Text size */
  margin-right: 10px;
  padding: 7.5px 0px 7.5px 10px;
  box-sizing: border-box; /* Ensure padding doesn't overflow the container */
  box-shadow: none; /* Remove shadow */
  font-weight: 400;
  height: 100%;
  font-family: 'Merriweather', serif; /* Apply globally */
}

/* Suggestion list container */
.suggestions-list {
  position: absolute;
  top: 100%; /* Position it directly below the input */
  left: 10; /* Align with the left side of the input */
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px; /* Rounded corners */
  max-height: 190px; /* Limit height to show a few items */
  overflow-y: auto; /* Allow scrolling */
  width: 95.5%; /* Ensure it matches the width of the search box */
  z-index: 10; /* Ensure it appears above other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  color: rgba(29, 41, 61, 0.65);
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: 'Merriweather', serif; /* Apply globally */
}

/* Individual suggestion items */
.suggestion-item {
  padding: 10px;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
  cursor: pointer;
  font-family: 'Merriweather', serif; /* Apply globally */
}

/* Highlight suggestion on hover */
.suggestion-item:hover {
  background-color: #f0f0f0; /* Lighter background on hover */
}

/* Highlight on click */
.suggestion-item:active {
  background-color: #e0e0e0; /* Slightly darker background when clicked */
}

.suggestion-item.focused {
  background-color: #f0f0f0; /* Highlight color */
  cursor: pointer;
}


/* Search button inside the input */
.landing-search-button {
  background-color: #139C8D; /* Button color */
  color: #fff; /* White text */
  padding: 7.5px 15px; /* Adjusted padding */
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  border-radius: 7.5px;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1); 
  font-family: 'Merriweather', serif; /* Apply globally */
}

/* Hover effect for search button */
.landing-search-box .search-button:hover {
  background-color: #139C8D; /* Change to black on hover */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3); /* Shadow for hover effect */
}

/* General search button styling */
.search-button {
  background-color: #139C8D; /* Main background color */
  position: inherit;
  font-family: 'Merriweather', serif; /* Apply globally */
}
