/* src/components/FAQPage.css */

.faq-page {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 100px;
  padding-top: 0px;
  font-family: Merriweather;
  color: #333;
  background-color: #f2f3f3;
  display: flex;
  flex-direction: column;
}

/* Dashboard Header Adjustments */
.faq-page .header {
  padding: 0px;
  height: 50px;
}

/* Header Row */
.faq-header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.faq-left {
  flex: 1;
}

.faq-right {
  flex: 3;
}

.faq-left h1 {
  font-size: 32px;
  margin: 0;
  text-align: left;
}

.faq-page .search-bar {
  padding: 0px;
  margin: 0px;
  margin-top: 2.1vw;
}

.faq-search {
  width: 100%;
  /* max-width: 400px; */
  padding: 12px 20px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.faq-search:focus {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Content Row */
.faq-content-row {
  display: flex;
  flex: 1; /* Allow content row to grow and fill available space */
  margin-top: 30px;
  color: #777;
  padding-left: 100px;
  padding-right: 100px;
}

.faq-left {
  flex: 1;
  margin-right: 40px;
}

.faq-right {
  flex: 3;
  overflow-y: auto; /* Enable scrolling if content exceeds viewport height */
}

/* Sidebar Styles */
.faq-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.faq-sidebar li {
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-sidebar li:hover,
.faq-sidebar li.active {
  color: #139C8D;
}

/* Main Content Styles */
.faq-item {
  /* Existing styles remain */
}

.faq-section h2 {
  font-size: 24px;
  color: #139C8D;
  margin-bottom: 20px;
}

.faq-item {
  margin-bottom: 20px;
  border-radius: 5px;
  border-bottom: 1px solid #eee;
  padding: 15px; /* Adjusted padding */
  transition: background-color 0.3s ease;
  background-color: white;
}

.faq-item h3 {
  font-size: 20px;
  margin: 0;
  cursor: pointer;
  position: relative;
  padding-right: 30px;
  transition: color 0.3s ease;
}

.faq-item h3:hover {
  color: #117a7a;
}

.faq-item .arrow {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  transition: transform 0.3s ease;
}

.faq-item.active h3 .arrow {
  transform: rotate(180deg);
}

.faq-answer {
  margin-top: 10px;
  animation: fadeIn 0.3s ease;
}

.faq-answer p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin: 0;
}

/* No Results Message */
.no-results {
  text-align: center;
  color: #777;
  font-size: 18px;
  margin-top: 20px;
}

/* Welcome Message */
.welcome-message {
  text-align: center;
  font-size: 18px;
  color: #555;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



/* src/components/FAQPage.css */

.faq-page {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 100px;
  padding-top: 0px;
  font-family: Merriweather;
  color: #333;
  background-color: #f2f3f3;
  display: flex;
  flex-direction: column;
}

/* Dashboard Header Adjustments */
.faq-page .header {
  padding: 0px;
  height: 50px;
  margin-left: -100px; /* Adjusted to make header full width */
  margin-right: -100px;
  width: calc(100% + 200px);
  background-color: white; /* Optional */
  margin-bottom: 100px;
  padding-left: 50px;
  padding-right: 30px
}

/* Header Row */
.faq-header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  
}

/* Responsive Design */

@media (max-width: 992px) {
.faq-page .header{
  height: 180px;
  padding: 20px;
}

  .faq-header-row,
  .faq-content-row {
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
  }

  .faq-left {
    margin-right: 0;
  }
  .faq-left h1{
    text-align: center;
  }

  .faq-search {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .faq-page {
    margin: 0;
  }
  .faq-header-row{
    padding-left: 0px;
  padding-right: 0px;
  }

  .faq-left h1 {
    font-size: 28px;
  }

  .faq-search {
    font-size: 16px;
  }

  .faq-sidebar li {
    padding: 8px 12px;
    font-size: 14px;
  }

  .faq-main .faq-section h2 {
    font-size: 22px;
  }

  .faq-item h3 {
    font-size: 18px;
  }

  .faq-answer p {
    font-size: 14px;
  }
}


/* ... rest of your existing styles ... */

/* Responsive Design */

/* ... existing media queries ... */
@media (max-width: 600px) {
  .faq-page {
    margin: 0;
    height: auto; /* Override fixed height */
    min-height: 100vh; /* Ensure at least viewport height */
  }

  .faq-header-row {
    padding-left: 0px;
    padding-right: 0px;
  }

  .faq-left h1 {
    font-size: 28px;
  }

  .faq-search {
    font-size: 16px;
  }

  .faq-sidebar li {
    padding: 8px 12px;
    font-size: 14px;
  }

  .faq-main .faq-section h2 {
    font-size: 22px;
  }

  .faq-item h3 {
    font-size: 18px;
  }

  .faq-answer p {
    font-size: 14px;
  }

  .faq-page .welcome-message{
    padding-left: 0px;
    padding-right: 0px;
  }
}
