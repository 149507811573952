/* Team Section Styles */
.team-section {
  min-height: 100vh;
  padding: 1vw;
  text-align: center; /* Center align all content */
  background-color: #f9f9f9; /* Lighten the background */
  padding-top: 5vw;
}

.team-section h2 {
  font-family: 'Merriweather', serif;
  font-size: 3em;
  color: #343A40;
  margin-bottom: 2rem;
  text-align: center;
  padding-top: 5vw;
}

.team-section h3{
  
  padding-top: 25px;
  padding-bottom:14px;
  font-size: 2.5em;
  color: #343A40;
  margin: 0;
  font-family: Merriweather, serif;
  text-align: center;
  margin-bottom: 40px; /* Add space between heading and text */
}
/* Team Content */
.team-content {
  max-width: 80vw;
  margin: 0 auto;
  color: #545b62;
  max-width: 900px;
  font-family: Merriweather;
  /* font-size: 5vw; */
}

/* Aligned Text */
.aligned-text p {
  text-align: justify;
  color: #343A40;
  font-size: 1vw;
  line-height: 1.6;
  margin-bottom: 0.5rem;

}

.team-container {
  display: flex;
  justify-content:center;
  gap: 40px;
}

.description {
  font-size: 1vw;
  text-align: justify;
  color: #343A40;
  max-width: 700px;
  margin: 0 auto 40px; /* Center text and add bottom margin */
  line-height: 1.6;
}

/* Centered Text */
.centered-text {
  text-align: center;
}

.centered-text p {
  color: #343A40;
  font-size: 1.1vw;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.team-member p {
  font-size: 16px;
  color: #bbbbbb;
}

/* Button Styling */
.footer-signup {
  background-color: #139C8D;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1); 
}

.footer-signup:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}


.footer-signup-button {
  background-color: #139C8D;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
}

.footer-signup-button:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 991px) {
  .team-section {
    min-height: 50vh; /* 50% of viewport height */
    padding: 20px 0; /* Add vertical padding for spacing */
  }
  .team-section h2 {
    font-size: 6vw; /* Adjust font size for smaller screens */
    text-align: center;
  }
  .team-content {
    width: 80%;
    /* font-size: 10vw; */
  }
  .aligned-text p,
  .centered-text p {
    font-size: 3vw; /* Adjust font size for better readability */
  }

  .team-section h3 {
    font-size: 2vw; /* Maintain or adjust as needed */
  }

  .description {
    font-size: 2vw; /* Adjust font size for better readability */
  }

  .footer-signup-button {
    width: 100%; /* Ensure buttons are easily tappable on mobile */
  }

  .footer-signup{
    font-size: 15px;
  }
}


@media (max-width: 768px) {
  .team-section{
    height: 100vh;
    padding-top: 25vw;
  }
}