/* Insights Section */
.insights-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px; /* Horizontal padding */
  box-sizing: border-box;
  font-family: 'Merriweather', serif; /* Apply globally */
}

.content-container {
  display: flex;
  flex-direction: row; /* Default to row on desktop */
  align-items: center;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

/* Images Column */
.images-column {
  flex: 1;
  padding-right: 20px;
}

.images-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between images */
}

.image-container {
  flex: 1;
  max-width: 100%;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

/* Text Column */
.text-column {
  flex: 1;
  padding-left: 20px;
}

.text-column h3 {
  font-family: 'Merriweather', serif; /* Apply Merriweather font */
  font-weight: bold;
  font-size: 2vw; /* Use vw for responsive font size */
  color: #343A40;
  margin: 0;
  line-height: 1.2;
  white-space: nowrap;
}

/* Responsive Design */
@media (max-width: 991px) {
  .text-column h3 {
    font-size: 3.2vw;
  }
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
  }
  .images-column {
    padding-right: 0;
    order: 2; /* Ensure images are below the text */
  }
  .text-column {
    padding-left: 0;
    margin-bottom: 20px;
    text-align: center;
    order: 1; /* Ensure text is above images */
  }
  .text-column h3 {
    font-size: 5vw; /* Increase font size slightly for smaller screens */
  }

  .images-row {
    flex-wrap: wrap; /* Allow wrapping if needed */
    gap: 15px;
    justify-content: center;
  }
  .image-container {
    flex: 1 1 45%; /* Images take up 45% of the container */
    max-width: 45%;
  }
}

@media (max-width: 480px) {
  .insights-section {
    min-height: 50vh;
  }
  .image-container {
    max-width: 100%;
  }
  .images-row {
    justify-content: center;
  }
}
