/* ScreenersPage.css */

.main-content {
  background-color: #fefefe;
}

/* Container for the screeners page */
.screeners-page-container {
  width: 100%;
  margin: 0 auto;
  background-color: white;
}

.min-headline {
  font-size: 1.5em;
  text-align: left;
  color: #333;
  margin-bottom: 10px;
  padding-left: 20px;
}

.ticker-container {
  display: flex;
  align-items: center;
}

.play-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 12px;
}

.play-button:hover {
  opacity: 0.8;
}

.screeners-container {
  padding: 50px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header row for screeners section, including tabs */
.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid #ddd;
  margin-bottom: 15px;
  font-family: "Inter", sans-serif;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.1em;
  color: #333;
  font-weight: bold;
  text-transform: capitalize;
  transition: color 0.3s;
}

.tab.active {
  color: #1976d2;
  border-bottom: 2px solid #1976d2;
}

/* Styling for the screeners table */
.table-container {
  overflow-x: auto; /* Allows horizontal scrolling on smaller screens */
  margin-top: 20px;
  font-family: "Inter", sans-serif;
}

.screeners-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: #888;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.screeners-table th,
.screeners-table td {
  padding: 22px 25px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 0.9em;
}

.screeners-table th {
  color: #1d3557;
  font-weight: bold;
  border-top: none;
}

.screeners-table td {
  color: #555;
}

.screeners-table tr {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.screeners-table tr:hover {
  background-color: #f1f1f1;
}

/* Loading state and no data message */
.screeners-table td[colspan="12"] {
  font-style: italic;
  color: #555;
  text-align: center;
  padding: 20px;
  background-color: #fafafa;
}

.screeners-table td.left-aligned {
  text-align: left; /* Align tickers to the left */
}

.screeners-table td.numeric-column {
  text-align: center; /* Align text to the left */
  /* font-family: 'Courier New', Courier, monospace; Optional: for better numeric readability */
}

.analysis-text {
  text-align: center;
  color: gray;
  font-size: 14px;
}

/* Pagination section */
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9em;
}

.pagination span {
  font-size: 14px;
  color: #555;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.page-btn {
  padding: 8px 16px;
  margin: 0 5px;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.page-btn:hover {
  background-color: #ddd;
}

.page-btn.active {
  color: #888;
}

.page-btn:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

/* Unique styles for filter container */
.screeners-filter-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column; /* Stack filter groups vertically */
  gap: 15px;
  width: 500px;
}

.screeners-filters {
  display: flex;
  flex-direction: column; /* Stack filter groups vertically */
  gap: 20px;
}

.screeners-filter-group {
  display: flex;
  flex-direction: column; /* Stack label and input vertically */
  align-items: flex-start; /* Align inputs and labels to the left */
}

.screeners-filter-group label {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left; /* Ensure labels are aligned to the left */
}

.screeners-filter-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  width: 100%; /* Adjust width to fit the container */
  max-width: 300px; /* Optional: set a max width for inputs */
}

.screeners-filter-group input::placeholder {
  font-size: 0.9em;
  color: #aaa;
}

.screeners-filter-container h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  text-align: left; /* Align the title to the left */
  color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .screeners-container {
    padding: 15px;
  }

  .screeners-table th,
  .screeners-table td {
    padding: 8px 10px;
    font-size: 0.8em;
  }

  .pagination span {
    margin-bottom: 10px;
  }
  .tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid #ddd;
    margin-bottom: 15px;
  }

  .tab {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 0.9em;
    color: #333;
    font-weight: bold;
    text-transform: capitalize;
    transition: color 0.3s;
  }

  .tab.active {
    color: #1976d2;
    border-bottom: 2px solid #1976d2;
  }
}

.screeners-page-container .pagination span {
  font-size: 1em;
}

@media (max-width: 768px) {
  .screeners-page-container .pagination span {
    font-size: 0.8em;
  }
}
