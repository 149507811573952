/* Disclaimer.css */

/* Popup Overlay Styling */
.disclaimer .popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it stays on top */
  animation: fadeIn 0.3s ease;
}

/* Popup Content Styling */
.disclaimer .popup-content {
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  text-align: justify;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  position: relative;
  animation: slideIn 0.3s ease;
  overflow-y: auto;
  max-height: 80vh; /* Limits the height to 80% of the viewport height */
  font-family: Arial, sans-serif;
}

/* Popup Header */
.disclaimer .popup-content h2 {
  margin-top: 0; /* Remove top margin since the close button is above */
  margin-bottom: 15px;
  color: #139C8D;
  text-align: center;
}

/* Popup Paragraph */
.disclaimer .popup-content p {
  margin-bottom: 20px;
  line-height: 1.6;
  color: #333;
}

/* Close Button Styling */
.disclaimer .close-button {
  position: absolute;        /* Positions the button relative to popup-content */
  top: 15px;                 /* Distance from the top of the popup */
  right: 15px;               /* Distance from the right of the popup */
  background-color: transparent; /* Transparent background */
  border: none;              /* Removes default border */
  font-size: 24px;           /* Larger font size for the icon */
  cursor: pointer;           /* Changes cursor to pointer on hover */
  color: #333;               /* Icon color */
  z-index: 1001;             /* Ensures the button appears above other elements */
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.disclaimer .close-button:hover {
  color: #117a7a; /* Change color on hover for feedback */
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
    .disclaimer .popup-content {
    max-height: 90vh;         /* Increases max-height to 90% on tablets and smaller devices */
    padding: 20px 15px;       /* Adjusts padding for better spacing on mobile */
  }

  /* Adjust Close Button Position */
  .disclaimer .close-button {
    top: 0px;
    right: 10px;
    font-size: 22px;
    margin: 10px auto;
    display: flex;             /* Use flexbox for alignment */
  justify-content: center;   /* Center align horizontally */
  align-items: center;
  }
}

@media (max-width: 480px) {
    .disclaimer .popup-content {
    max-height: 95vh;         /* Sets max-height to 95% on very small screens */
    padding: 15px 10px;
  }

  .disclaimer .close-button {
    top: 0px;
    right: 8px;
    font-size: 20px;
    margin: 10px auto;
    display: flex;             /* Use flexbox for alignment */
  justify-content: center;   /* Center align horizontally */
  align-items: center;
  }
}

/* Optional: Ensure Smooth Scrolling Inside Popup */
.disclaimer .popup-content::-webkit-scrollbar {
  width: 8px;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.popup-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.disclaimer .popup-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

/* Disclaimer.css */

/* Popup Overlay Styling */
.disclaimer .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it stays on top */
    animation: fadeIn 0.3s ease;
  }
  
  /* Popup Content Styling */
  .disclaimer .popup-content {
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    text-align: justify;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    position: relative;
    animation: slideIn 0.3s ease;
    overflow-y: auto;
    max-height: 80vh; /* Limits the height to 80% of the viewport height */
  }
  
  /* Popup Header */
  .disclaimer .popup-content h2 {
    margin-top: 0; /* Remove top margin since the close button is above */
    margin-bottom: 15px;
    color: #139C8D;
    text-align: center;
  }
  
  /* Popup Paragraph */
  .disclaimer .popup-content p {
    margin-bottom: 20px;
    line-height: 1.6;
    color: #333;
  }
  
  /* Close Button Styling */
  .close-button {
    position: absolute;        /* Positions the button relative to popup-content */
    top: 15px;                 /* Distance from the top of the popup */
    right: 15px;               /* Distance from the right of the popup */
    background-color: transparent; /* Transparent background */
    border: none;              /* Removes default border */
    font-size: 24px;           /* Larger font size for the icon */
    cursor: pointer;           /* Changes cursor to pointer on hover */
    color: #333;               /* Icon color */
    z-index: 1001;             /* Ensures the button appears above other elements */
    display: flex;             /* Use flexbox for alignment */
    justify-content: center;   /* Center align horizontally */
    align-items: center;
    transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
  }
  
  .close-button:hover {
    color: #117a7a; /* Change color on hover for feedback */
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { transform: translateY(-50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .disclaimer .popup-content {
      max-height: 90vh;         /* Increases max-height to 90% on tablets and smaller devices */
      padding: 20px 15px 20px 15px;       /* Adjusts padding for better spacing on mobile */
    }
  
    /* Adjust Close Button Position */
    .close-button {
        top: 0px;   /* Keep the same top position */
      right: 15px; /* Keep the same right position */
      font-size: 24px; /* Keep the same font size */
      display: flex;             /* Use flexbox for alignment */
  justify-content: center;   /* Center align horizontally */
  align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    .disclaimer .popup-content {
      max-height: 95vh;         /* Sets max-height to 95% on very small screens */
      padding: 15px 10px 15px 10px;       /* Reduces padding to maximize content area */
    }
  
    .close-button {
      top: 0px;   /* Keep the same top position */
      right: 15px; /* Keep the same right position */
      font-size: 24px; /* Keep the same font size */
      width: 1px;
      display: flex;             /* Use flexbox for alignment */
  justify-content: center;   /* Center align horizontally */
  align-items: center;
    }
  }
  
  /* Optional: Ensure Smooth Scrolling Inside Popup */
  .disclaimer .popup-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .disclaimer .popup-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
  }
  
  .disclaimer .popup-content::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  