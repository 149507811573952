/* Global Box Sizing */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  /* Main Section Styling */
  .reddit-section {
    text-align: center;
    font-family: 'Merriweather', serif;
  }
  
  /* Title Styling */
  .reddit-section-title {
    color: #1D3557;
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 30px;
  }
  
  /* Image Container */
  .reddit-image-container {
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  /* Image Styling */
  .reddit-image {
    width: 75%;
    height: auto;
    display: none; /* Initially hide images */
  }
  
  /* Desktop Image Styling */
  .reddit-desktop {
    display: block; /* Show desktop image by default */
  }
  
  /* Mobile Image Styling */
  .reddit-mobile {
    display: none; /* Hide mobile image by default */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .reddit-desktop {
      display: none; /* Hide desktop image on small screens */
    }
  
    .reddit-mobile {
      display: inline-block; /* Show mobile image on small screens */
    }
  }  