.profile-header  {
    display: flex;
    justify-content: start;
    width: 100%;
    margin-bottom: 1em;
}
.profile-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: start;
    margin-bottom: 1em;
}
.profile-info-container span {
    margin-bottom: 0.5em;
    font-size: 18px;
    font-weight: 600;
}
.profile-info-container input[type=text] {
    margin-bottom: 1em;
    /* width: 100%; */
    padding: 15px;
    border-radius: 10px;
    border: 2px solid #139C8D;
    color: rgba(0, 0, 0, 0.75);
    /* background-color: #FFF; */
    font-size: 15px;
}
.profile-info-container input[type=checkbox] {
    margin-bottom: 1em;
    padding: 15px;
    border-radius: 10px;
    border: 2px solid #139C8D;
    color: rgba(0, 0, 0, 0.75);
    background-color: #FFF;
    float: left;
}
.profile-info-container button {
    margin-bottom: 1em;
    padding: 10px 15px;
    border-radius: 10px;
    color: #b22222;
    background-color: #ff99a1;
    font-size: 15px;
    font-weight: 600;
    border: 0;
    float: left;
    transition: 0.2s all ease-in-out;
}
.profile-info-container button:hover {
    opacity: 0.75;
}
.profile-info-container button:disabled {
    pointer-events: none;
    opacity: 0.5;
}
.profile-info-container button:disabled:hover {
    opacity: 0.5;
}
.accounts-page .pagination span{
    font-size: 1em;
}
.subscription-status  {
    font-weight: 400 !important;
    font-size: 15px !important;
    color: gray;
    background-color: rgba(239, 239, 239, 0.6);
    padding: 5px 7.5px;
    border-radius: 5px;
}
#cancel-sub-button  {
    font-size: 15px;
    font-weight: 400;
    color: gray;
    text-decoration: underline;
    font-style: italic;
}
#account-popup-overlay  {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1500;
    display: none;
}
#account-popup  {
    position: absolute;
    padding: 15px;
    background-color: white;
    z-index: 1500;
    border-radius: 10px;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#account-popup span {
    margin-bottom: 1em;
    display: flex;
}
#account-popup div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
}
#account-popup div button {
    padding: 5px 15px;
    transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}
#account-popup div button:hover {
    opacity: 0.75;
    cursor: pointer;
}
#account-popup div button:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    border: none;
}
#account-popup div button:nth-child(2) {
    margin-left: 10px;
    background-color: #139C8D;
    color: white;
    border-radius: 5px;
    border: none;
}

@media (max-width:768px) {
    .accounts-page .pagination span{
        font-size: 0.8em;
    }
    .profile-info-container input[type=text] {
        margin-bottom: 1em;
        padding: 10px;
        border-radius: 10px;
        border: 2px solid #139C8D;
        color: rgba(0, 0, 0, 0.75);
        font-size: 15px;
    }
    .profile-info-container div {
        display: flex;
        flex-direction: row;
    }
    .profile-info-container span em {
        margin-bottom: 0.5em;
        font-size: 14px;
        display: flex;
    }
}