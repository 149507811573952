.prediction-output-container {
  width: 100%;
  font-family: Arial, sans-serif;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px 0;
}

/* Navigation Styles */
.navigation-bar {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.nav-link {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  transition: color 0.3s;
}

.nav-link.active {
  color: #1d3557;
  border-bottom: 2px solid #1d3557;
}

.gpt-output-contents {
  justify-content: center;
  margin-top: 15px;
  font-family: "Inter";
}

.prediction-text {
  font-size: 16px;
  padding: 0px;
  margin: 0;
  line-height: normal;
  width: 100%;
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 1.5;
  text-align: justify;
  text-align-last: left;
  font-weight: normal;
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin-left: 70px;
}

.data-analysis-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: disc;
  margin-left: 20px; /* Indentation for the bullets */
  row-gap: 10px; /* Vertical spacing */
  column-gap: 20px; /* Horizontal spacing */
}

.data-analysis-list li {
  flex: 1 1 calc(50% - 10px); /* Two items per row */
}

/* Main Layout Grid */
.second-tab {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

/* Left Column - Graph, Technical Analysis, and Influences */
.quant-left-column {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

/* Graph Container */
.predictiion-graph-container {
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  /* height: 450px; */
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  text-align: left;
  max-width: 1200px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  font-family: "Inter";
}

.graph-container {
  width: 100%;
  height: 100%;
  text-align: center;
}

.graph-container h3 {
  margin-bottom: 20px;
  color: #1d3557;
  font-size: 18px;
}

.graph-container p {
  font-size: 14px;
  margin-top: 10px;
}

.no-graph-message {
  text-align: center;
  height: auto;
}

/* Technical Analysis */
.technical-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 25px; /* Add some spacing between graph and technical analysis */
  font-family: "Inter";
}

.technical-container h3 {
  font-family: 'Merriweather', serif; /* Apply Merriweather font */
}

/* Influences Boxes */
.influences-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 25px;
}

.influence-box {
  padding: 20px;
  border-radius: 12px;
  min-height: 200px;
  position: relative;
  transition: transform 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.influence-box:hover {
  transform: translateY(-2px);
}

/* Positive Influence Box */
.influence-positive {
  background-color: #ccfdd6;
  border: none;
  color: #333;
}

/* Negative Influence Box */
.influence-negative {
  background-color: #f99a9a;
  border: none;
  color: #333;
}

/* Icon Styling */
.icon {
  font-size: 2.2vw;
  font-weight: bold;
  position: absolute;
  left: 20px;
}

.influence-positive .icon {
  color: #28a745;
}

.influence-negative .icon {
  color: #dc3545;
}

/* Influence Text Styling */
.influence-text h3 {
  padding-top: 2.2vw;
  margin-bottom: 8px;
  font-size: 18px;
  color: #333;
}

.influence-text ul {
  list-style-type: none;
  padding: 0;
}

.influence-text ul li {
  font-size: 14px;
  margin-bottom: 6px;
  font-weight: normal;
}

.influence-text ul li::before {
  content: "•";
  margin-right: 8px;
  color: #000;
  font-size: 14px;
}

.influence-text ul li span {
  font-weight: normal;
  color: #555;
}

/* Right Column - Reddit, News, and Insider Trading */
.qual-right-column {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.news-post {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
}
.news-post-container {
  margin-top: 1em;
}
.news-post-container .news-post:last-child {
  margin-bottom: 0 !important;
}
.news-post-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.news-post-header p {
  margin: 0;
  font-size: 15px;
  color: #202020;
  flex-shrink: 0;
}
.news-post-header span {
  margin: 0;
  font-size: 14px;
  color: #555;
  flex-shrink: 0;
}
.news-post-title {
  font-size: 16px;
  font-weight: normal;
  color: #4e3ff0;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  white-space: normal;
  margin-bottom: 5px;
  text-align: left;
}
.news-post-title:hover {
  text-decoration: underline;
}

/* Reddit Posts Container */
.reddit-posts-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: left;
  max-width: 1200px;
  font-family: "Inter";
}

.reddit-posts-container h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.reddit-posts-container ul {
  list-style: none;
  padding: 0;
}

.reddit-post {
  margin-bottom: 10px;
  font-size: 16px;
}

.reddit-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.live-price-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 475px;
}
.live-price-card h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #1d3557;
}
.live-price-card p {
  font-size: 16px;
  color: #333;
  margin: 0;
}
.company-info-stat-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.company-info-stat-title {
  font-family: "Merriweather";
  font-weight: 600;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
.company-info-stat-value {
  font-family: "Merriweather";
  text-align: end;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}

.author-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image scales properly */
}

.reddit-post-header {
  display: flex;
  align-items: center; /* Aligns logo and title vertically */
  gap: 10px; /* Space between logo and title */
  margin-bottom: 5px;
}

.reddit-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.post-title {
  font-size: 15px;
  font-weight: normal;
  color: #1a0dab;
  text-decoration: none;
  flex-grow: 1; /* Allows title to occupy available space */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  white-space: normal;
  text-align: left;
}

.post-title:hover {
  text-decoration: underline;
}

.reddit-post a {
  color: #4e3ff0;
  text-decoration: none;
}

.reddit-post a:hover {
  text-decoration: underline;
}

.reddit-post p {
  margin: 0;
  font-size: 12px;
  color: #555;
}

.reddit-post-meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 12px;
  color: #666;
}

.oldest-comment-time {
  font-size: 12px;
  color: #6c757d;
  margin-top: 4px;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.meta-item svg {
  font-size: 16px;
  color: #555;
}

.meta-item .upvotes {
  color: #28a745;
}

.meta-item .downvotes {
  color: #dc3545;
}

.meta-item .comments {
  color: #007bff;
}

.loading-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-text {
  margin-top: 10px; /* Add spacing between the spinner and the text */
  font-size: 14px;
  color: #555; /* Optional: Adjust text color */
}

/* News Section */
.news-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  
}

.news-section h3 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.news-table {
  width: 100%;
  border-collapse: collapse;
}

.news-icon-cell {
  width: 50px;
  text-align: left;
  font-size: 60px;
  padding: 10px;
}

.news-table td {
  padding: 10px;
  vertical-align: top;
}

.news-table td:last-child {
  font-size: 14px;
  color: #333;
  text-align: justify;
  justify-content: left;
}

/* Insider Trading Section */
.insider-trading-container {
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
.prediction-text-container {
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 1.5;
  text-align: justify;
  text-align-last: left;
  font-weight: normal;
  font-family: Arial, sans-serif;
  max-width: 1200px;
  font-family: "Merriweather"
}

/* Error States */
.no-graph-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  border-radius: 12px;
}

.error-message {
  font-size: 16px;
  color: #dc3545;
  text-align: center;
}

.collapsible-section {
  margin-bottom: 20px;
}

.collapsible-question {
  font-weight: bold;
  cursor: pointer;
  /* background-color: #f9f9f9; */
  padding: 10px;
  border-radius: 5px;
  border-bottom: 0px;
}

.collapsible-answer {
  margin-top: 10px;
  /* padding-left: 20px; */
  /* color: #333; */
}

.collapsible-answer p {
  margin: 5px 0;
}

.more-button {
  background-color: #139c8d; /* Primary color matching your theme */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.more-button:hover {
  background-color: #117a80; /* A slightly darker shade for the hover effect */
}

.more-button:focus {
  outline: none;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .second-tab {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .influences-container {
    grid-template-columns: 1fr 1fr;
  }
  /* 
  .predictiion-graph-container {
    height: 350px;
  } */
}

@media (max-width: 768px) {
  .influences-container {
    grid-template-columns: 1fr;
  }

  .navigation-bar {
    flex-wrap: wrap;
  }

  .nav-link {
    font-size: 14px;
    padding: 8px 15px;
  }

  /* .predictiion-graph-container {
    height: 300px;
  } */

  /* Container spacing and padding adjustments */
  .predictiion-graph-container,
  .reddit-posts-container,
  .technical-container,
  .news-section,
  .insider-trading-container {
    padding: 15px;
    margin-bottom: 20px;
    /* padding-top:0px; */
  }

  /* Text adjustments */
  .prediction-text {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  /* Positive Influence Box */
  .influence-positive {
    background-color: #ccfdd6;
    border: none;
    color: #333;
  }

  /* Negative Influence Box */
  .influence-negative {
    background-color: #f99a9a;
    border: none;
    color: #333;
  }

  /* Influence Text Styling */
  .influence-text h3 {
    padding-top: 2.2vw;
    margin-bottom: 8px;
    font-size: 18px;
    color: #333;
    text-align: center;
  }

  /* Icon Styling */
  .icon {
    font-size: 7.5vw;
    font-weight: bold;
    position: absolute;
    left: 20px;
  }

  .influence-positive .icon {
    color: #28a745;
  }

  .influence-negative .icon {
    color: #dc3545;
  }
  .influence-text ul li {
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: normal;
    text-align: left;
  }

  .data-analysis-list {
    display: block; /* Stack items vertically */
  }

  .data-analysis-list li {
    flex: none; /* Reset flex properties */
    margin-bottom: 10px; /* Add spacing between items */
  }

  /* Ensure the news section is properly aligned in mobile view */
  .news-post {
    text-align: left; /* Left align the news content */
    display: flex;
    flex-direction: column;
  }

  .news-post-header {
    display: flex;
    flex-direction: column; /* Stack publisher and date vertically */
    align-items: flex-start; /* Align elements to the left */
  }

  .news-post-header span {
    text-align: left;
    width: 100%;
  }

  .news-post-title {
    font-size: 15px;
    text-align: left;
    display: block;
    -webkit-line-clamp: 2;
  }

  /* Adjust spacing to prevent misalignment */
  .news-section {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .prediction-output-container {
    padding: 10px;
  }

  .technical-container h3 {
    text-align: center;
  }

  .graph-container h3,
  .reddit-header h3,
  .news-section h3 {
    font-size: 16px;
  }

  .influence-box {
    min-height: 180px;
  }

  .reddit-posts-container,
  .news-section,
  .technical-container,
  .prediction-text-container .prediction-text {
    padding: 15px;
    display: flex !important;
    flex-direction: column;
  }

  /* Ensures everything aligns properly on very small screens */
  .news-posts-container {
    padding: 15px;
    text-align: left;
  }

  .news-post {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
  }

  .news-post-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .news-post-header span {
    font-size: 12px;
  }

  .news-post-title {
    word-break: break-word;
  }
}

.prediction-output {
  font-family: 'Merriweather', serif; /* Apply globally */
}

.prediction-output h3 {
  font-family: 'Merriweather', serif; /* Apply Merriweather font */
}

/* Add any other specific styles here */
