/* LandingPage.css */

/* Reset Styles */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
}

.hidden-heading {
  position: absolute;
  left: -9999px;
  top: -9999px;
  height: 1px;
  width: 1px;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
}

/* Landing Page Styling */
.landing-page {
  text-align: center;
  background-color: #F8F8F5;
  color: rgb(52, 52, 52);
  scroll-behavior: smooth;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.product-page{
  min-height: 100vh;
}

.landing-cta-button:hover {
  opacity: 0.75;
}

/* Header Styling */
.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 80px;
}

/* Logo Styling */
.header h3 {
  font-size: 1.5em;
  margin: 0;
  font-family: Merriweather, serif;
  color: #1D3557;
}

.header h3 a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Keeps the original color */
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.header h3 a:hover {
  text-decoration: none; /* Ensures no underline on hover */
}

/* Header Right Container */
.landing-page .header-right {
  display: flex;
  align-items: center;
  margin-right: 0px;
}

.landing-section-name  {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
  border: 1px solid rgba(29, 41, 61, 0.75);
  width: fit-content;
  padding: 3px 7px;
  border-radius: 20px;
}
.landing-section-title  {
  font-family: "Merriweather", serif;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: start;
}
.landing-section-subtext  {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: rgba(29, 41, 61, 0.75);
  text-align: left;
  margin-bottom: 1em;
}
.landing-testimonials-grid  {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5%;
  margin-top: 4em;  
}
.landing-testimonial-container {
  padding: 20px;
  background-color: #139C8D;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  border-radius: 15px;
  margin-bottom: 2em;
}
.landing-testimonial-text {
  font-family: "Merriweather", serif;
  font-size: 18px;
  color: white;
  text-align: left;
  font-weight: 400;
  opacity: 0.9;
}
.landing-testimonial-author {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
}
.landing-author-name {
  font-size: 18px;
  color: white;
  font-weight: 600;
}
.landing-author-role {
  font-size: 15px;
  color: white;
  font-weight: 400;
  opacity: 0.75;
}


/* Sign-in Button Styling */
.signin-button {
  background-color: #139C8D;
  color: #F8F8F5;
  padding: 7.5px 15px;
  border: none;
  border-radius: 7.5px;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
  font-weight: 600;
  font-size: 16px;
  font-family: 'Inter';
}

.signin-button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* Hamburger Menu Icon */
.menu-icon {
  display: none; /* Hidden on desktop */
  font-size: 5.5vw;
  color: #343A40;
  cursor: pointer;
  margin-left: 1.5vw;
}

/* Tabs Button Wrapper */
.tabs-button-wrapper {
  display: flex;
  align-items: center;
  transition: max-height 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin-right: 30px;
}

/* Navigation Tabs */
.tabs-button-wrapper .tabs {
  display: flex;
}

.tabs-button-wrapper .tabs a {
  margin-left: 30px;
  color: #1D293D;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Inter';
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.tabs-button-wrapper .tabs a:hover {
  opacity: 0.6;
  cursor: pointer;
}

.landing-page .tabs{
  border-bottom: none;
}

.landing-searchbox-subtext {
  font-family: 'Inter';
  margin-top: 5px;
  color: #1D293D;
  opacity: 0.65;
  font-size: 14px;
}

@media (max-width: 915px) {
  .tabs-button-wrapper {
    display: none;
  }
  .menu-icon {
    display: block;
  }
}

/* Hide Navigation Links on Mobile */
@media (max-width: 900px) {
  .landing-section-name  {
    font-size: 12px;
  }
  .landing-section-title  {
    font-size: 20px;
  }
  .landing-section-subtext {
    font-size: 15px;
  }
  .carousel-container {
    padding: 10% 5%;
  }
  .landing-testimonials-grid  {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 3%;
    margin-top: 2em;  
  }
  .landing-testimonial-text  {
    font-size: 18px;
    margin-bottom: 4em;
  }
  .landing-testimonial-container  {
    height: 350px;
    justify-content: space-between;
    padding: 30px;
  }
}

/* Hide the Hamburger Icon on Larger Screens */
/* @media (min-width: 816px) {
  .menu-icon {
    display: none;
  }
} */

/* Background Image Section */
#background {
  background-image: url('../assets/images/background_cropped.jpg'); /* Ensure correct path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  aspect-ratio: 36 / 9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

/* Content Section */
.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  justify-content: center;
  align-items: center;
}
.landing-header-text {
  font-family: 'Merriweather';
  color: #1D3557;
  font-size: 55px;
  font-weight: 400;
  margin-bottom: 15px;
}
.landing-header-bold-text {
  font-family: 'Merriweather';
  color: #1D3557;
  font-size: 39px;
  font-weight: 700;
}
.landing-header-subtext  {
  font-family: 'Inter';
  font-style: italic;
  color: #1D293D;
  opacity: 0.75;
  font-size: 18px;
  width: 50%;
  margin-bottom: 45px;
}

.search-box-wrapper {
  width: 80%;
}

.search-box-wrapper .search-box {
  width: 100%;
}

.search-box input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #139C8D;
  color: #292D32;
  font-size: 16px;
  box-sizing: border-box;
}

.right-column p {
  margin-top: 10px;
  font-size: 20px;
  color: #343A40;
  font-family: Montserrat, sans-serif;
  font-style: italic;
  text-align: left;
  padding-bottom: 20;
  font-size: small;
}

/* Responsive Design for Mobile */
@media (max-width: 815px) {
  /* Adjust Header Layout */
  .header {
    /* flex-direction: row; */
    justify-content: space-between;
    align-items: center;
    /* padding: 10px 20px; */
    height: 18vw;
    
  }

  .header h3 {
    font-size: 4.9vw;
    white-space: nowrap;
  }

  .header-right {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1001; /* Ensure it's on top */
  }

  .signin-button {
    margin-left: 0;
    width: auto; /* Adjust width for better alignment */
    padding: 1.5vw; /* Adjust padding for better touch targets */
    margin-right: 1vw;
    font-size: 3.2vw;
    border-radius: 0.5vw;
  }

  /* Search Row Adjustments */
  .search-row {
    flex-direction: column;
    padding: 20px;
  }

  .content{
    padding-top: 0vw;
  }

  .left-column{
    width: 100%;
    padding: 10px 0;
  }

  .right-column {
    width: 115%;
    padding: 0px;
    padding-top: 1vw;
    display: block;
  }

  .left-column h3 {
    font-size: 8vw;
    text-align: left;
  }

  .left-column p {
    font-size: 4vw;
  }

  /* Background image height adjustment */
  #background{
    aspect-ratio: 30 /16;
  }

  .search-row .left-column h3 {
    align-items: center;
    text-align: left;
    font-size:x-large;
  }

  .search-row .left-column p {
    text-align: justify;
    padding-top: 5vw;
    font-style: italic;
  }

  .search-row .right-column p {
    padding-top: 3vw;
    font-size: 3vw;
    padding-bottom: 50px;
    
  }

  .landing-page .search-button {
    font-size: 4vw;
  }

  .landing-page .search-box {
    width: 110%;
    align-self: center;
  }

  .search-box-wrapper {
    align-self: center;
  }

  .search-box ::placeholder{
    font-size: 3.5vw;
  }

  .search-box{
    width: 90vw;
  }
}

/* Additional Fixes */
.landing-page .search-box {
  padding-left: 0px;
}

@media (max-width: 2000px) {
  .tabs-button-wrapper {
    padding-left: 50vw; /* Adjust padding for medium screens */
  }
}

@media (max-width: 1600px) {
  .tabs-button-wrapper {
    padding-left: 40vw; /* Adjust padding for medium screens */
  }
}

@media (max-width: 1440px) {
  .tabs-button-wrapper {
    padding-left: 30vw; /* Adjust padding for medium screens */
  }
}

@media (max-width: 1300px) {
  .tabs-button-wrapper {
    padding-left: 28vw; /* Adjust padding for medium screens */
  }
}

@media (max-width: 1200px) {
  .tabs-button-wrapper {
    padding-left: 20vw; /* Adjust padding for medium screens */
  }
}

@media (max-width: 1100px) {
  .tabs-button-wrapper {
    padding-left: 10vw; /* Adjust padding for medium screens */
  }
  
}
@media (max-width: 535px) {
  .landing-header-text {
    font-size: 45px;
    width: 95%;
  }
  .landing-header-bold-text {
    font-size: 39px;
    
  }
  .landing-header-subtext  {
    font-size: 15px;
    font-style: italic;
    width: 90%;
  }
}
@media (max-width: 480px) {
  .tabs-button-wrapper {
    padding-left: 0vw; /* Adjust padding for very small screens */
  }
  .landing-header-text {
    font-size: 35px;
  }
  .landing-header-bold-text {
    font-size: 21px;
  }
}

/* Carousel Container */
.carousel-container {
  width: 100%;
  height: 100vh;
  position: relative;
  min-height: 100vh; /* Ensure the carousel takes up the full viewport height */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 5% 10%;
}
/* Carousel Slide */
.carousel-slide {
  width: 100% !important;
  height: 100% !important;
}

/* Position the slick-dots (navigation dots) inside the carousel */
.carousel-container .slick-dots {
  position: absolute;
  bottom: 20px; /* Adjust vertical position from the bottom */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

/* Customize the appearance of the dots */
.carousel-container .slick-dots li button:before {
  font-size: 12px;
  color: #7e7e7a;
  opacity: 0.6;
}

.carousel-container .slick-dots li.slick-active button:before {
  color: #139C8D; /* Active dot color */
  opacity: 1;
}

/* Hide arrows if not using them */
.carousel-container .slick-prev,
.carousel-container .slick-next {
  display: none !important;
}

/* Optional: Adjust slide content to be centered */
.carousel-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure each slide fills the viewport height */
  box-sizing: border-box;
}

/* Prevent vertical scrolling when carousel is active */
body.no-scroll {
  overflow: hidden;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .carousel-container {
    min-height: 100vh;
    padding: 10%;
  }

  .carousel-slide > div {
    padding: 20px;
  }
}
/* Carousel Container */
.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 100vh; /* Ensures the carousel fills the viewport height */
}

/* Slick Slider Overrides */
.slick-slider {
  overflow: hidden; /* Hide overflow */
}

.slick-list {
  overflow: hidden; /* Hide overflow */
}

.slick-track {
  display: flex !important; /* Use flexbox for slides */
}

.slick-slide {
  width: 100% !important;
  height: 100vh; /* Each slide fills the viewport height */
  display: inline-block; /* Arrange slides horizontally */
  float: none !important;
}

/* Prevent vertical scrolling */
body {
  overflow-y: hidden; /* Disable vertical scroll */
}

body.allow-scroll {
  overflow-y: auto; /* Re-enable vertical scroll when all slides are visited */
}

/* Optional: Customize navigation dots */
.carousel-container .slick-dots {
  position: absolute;
  bottom: 20px; /* Position dots at the bottom */
  left: 50%;
  transform: translateX(-50%);
}

.carousel-container .slick-dots li button:before {
  font-size: 12px;
  color: #7e7e7a;
  opacity: 0.6;
}

.carousel-container .slick-dots li.slick-active button:before {
  color: #139C8D;
  opacity: 1;
}

/* Prevent vertical scrolling */
body {
  overflow-y: hidden;
}

/* Allow vertical scrolling when all slides are visited */
body.allow-scroll {
  overflow-y: auto;
}

/* Carousel Container */
.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 100vh; /* Ensures the carousel fills the viewport height */
}

/* Carousel Slide */
.carousel-slide {
  width: 100% !important;
  height: 100vh; /* Each slide fills the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Carousel Container */
.carousel-container {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 100vh; /* Ensures the carousel takes up the full viewport height */
  overflow: hidden;
}

/* Swiper Styles */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Each slide fills the viewport height */
  box-sizing: border-box;
  padding: 0px; /* Padding for content inside slides */
}

/* Position Swiper Pagination Dots */
.swiper-pagination {
  position: absolute;
  bottom: 20px; /* Same as Slick dots */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

/* Customize Pagination Dots */
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #7e7e7a;
  opacity: 0.6;
  transition: background 0.3s ease, opacity 0.3s ease;
}

.swiper-pagination-bullet-active {
  background: #139C8D;
  opacity: 1;
}

/* Responsive Adjustments for Swiper */
@media (max-width: 1200px) {
  .swiper-slide {
    height: 85vh; /* Adjust height for medium screens */
  }
}

@media (max-width: 768px) {
  .swiper-slide {
    height: 75vh; /* Adjust height for small screens */
    padding: 0px;
  }

  .swiper-pagination {
    bottom: 15px; /* Adjust dots position */
  }

  .landing-page .footer-section {
    width: 100%;
    text-align: center;
    padding: 1em;
    position: relative;
    bottom: 0;
    transform-origin: bottom;
  }

  /* Footer Top and Bottom Row adjustments */
  .landing-page .footer-top,
  .landing-page .footer-bottom {
    display: flex;
    flex-direction: row; /* Keep items in a row */
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow wrapping */
  }

  /* Align logo and button in a row */
  .landing-page .footer-logo-signup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  /* Left-align logo */
  .landing-page .footer-logo {
    text-align: left;
  }

  /* Right-align call-to-action button */
  .landing-page .footer-call-to-action {
    text-align: right;
  }

  /* Adjust the footer-bottom row */
  .landing-page .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  /* Align footer links and social icons side by side */
  .landing-page .footer-bottom-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  /* Footer Social Icons */
  .landing-page .footer-social {
    margin: 5px;
    justify-content: flex-start;
  }

  /* Footer Links Row */
  .landing-page .footer-links-row {
    justify-content: center;
    font-size: small;
  }

  /* Adjust text size for smaller screens */
  .landing-page .footer-section h3,
  .landing-page .footer-section li,
  .landing-page .footer-section button {
    font-size: 0.9em;
  }

}

@media (max-width: 480px) {
  .swiper-slide {
    height: 65vh; /* Adjust height for very small screens */
    padding: 0px;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
  }

  .swiper-pagination {
    bottom: 10px; /* Adjust dots position further */
  }
}

/* Add these styles to hide the search bar and try free button */
.search-container {
  display: none; /* This will hide the search bar */
}

.try-free-button {
  display: none; /* This will hide the try free button */
}

