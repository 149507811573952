/* General Page Styles */
.login-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f5fd; /* Light background to blend in with the rest of the site */
  font-family: "Inter", sans-serif;
}

/* Card Container for Login */
.login-card {
  background-color: white;
  padding: 60px 40px;
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.8s ease;
}

/* Header Section */
.login-header {
  margin-bottom: 30px;
}

.login-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 10px;
  padding-bottom: 30px;
}

.login-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

/* Google Sign-In Button */
.login-button {
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-color: #1d3557;
  background-color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-family: "Inter", sans-serif;
}

.google-icon {
  width: 21px; /* Adjust size of the Google icon */
  height: 21px;
  margin-right: 10px; /* Space between icon and text */
  vertical-align: middle;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  color: white;
  background-color: #1d3557;
}

.login-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(54, 162, 240, 0.4);
}

/* Fade-In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .login-card {
    width: 90%;
  }

  .login-title {
    font-size: 1.6rem;
  }

  .login-button {
    font-size: 14px;
  }
}
