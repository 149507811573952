/* ==========================================================================
   Global Styles & Layout
   ========================================================================== */
.logged-in-page {
  display: flex;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background-color: white;
  flex-direction: column;
}

.welcome-message {
  margin-top: 100px;
  padding-left: 75px;
  padding-right: 75px;
  margin-bottom: 100px;
  font-style: italic;
  justify-content: center;
}

/* ==========================================================================
     Header
     ========================================================================== */
.header-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: var(--header-height);
  border-bottom: 1px solid #E5E7EB;
}

.header-left {
  display: flex;
  align-items: center;
}
.header-left h3 {
  font-size: 1.4em;
  margin: 0;
  font-family: Merriweather, serif;
  color: #1d3557;
  white-space: nowrap;
  padding-top: 10px 10px;
}
.header-left h3 a {
  text-decoration: none;
  color: inherit;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.header-left h3 a:hover {
  text-decoration: none;
}

.header-nav {
  display: flex;
  justify-content: right; /* Center the navigation items */
  flex-grow: 1; /* Allow navigation to grow and fill the center */
  gap: 100px; /* Increased space between each navigation link */
  font-family: Merriweather;
  font-size: 30px;
  font-weight: bold;
  padding-right: 20px;
}
.header-nav a {
  font-size: 16px;
  color: black;
  text-decoration: none;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.header-nav a:hover {
  color: #139c8d;
}

.header-right {
  display: flex;
  align-items: center;
  /* margin-right: 30px;  */
}

/* ==========================================================================
     Logout Button & User Icon
     ========================================================================== */
.logout-button {
  background-color: #fff;
  color: #139c8d;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 20px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.logout-button:hover {
  background-color: #005bb5;
}

/* (Merged duplicate .user-icon definitions) */
.user-icon {
  background-color: #139c8d;
  color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  font-size: 1em;
  font-family: "Inter";
}

/* ==========================================================================
     Hamburger Menu
     ========================================================================== */
.hamburger-menu {
  display: none; /* Hidden by default */
  flex-direction: column;
  justify-content: space-between;
  /* width: 6.5vw;
    height: 6.5vw; */
  background: none;
  border: none;
  cursor: pointer;
  padding-top: 10px;
  margin-right: 10px;
}
.hamburger-menu svg {
  height: 25px;
  width: 25px;
}

/* ==========================================================================
     Sidebar Navigation
     ========================================================================== */
.sidebar-nav {
  width: 200px;
  background-color: white;
  padding: 20px 0;
  height: calc(100vh - 80px); /* Adjust for header height */
  position: fixed;
  left: 0;
  top: 80px; /* Match header height */
  overflow-y: auto;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}

.nav-section {
  display: flex;
  flex-direction: column;
  padding-top: 0;
}

.nav-section + .nav-section {
  margin-top: 20px; /* Add space between sections */
}

.section-header {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 10px 20px;
  color: #6B7280;
  font-size: 12px;
  margin: 0;
  font-weight: 600;
  font-family: 'Merriweather', serif;
}

.sidebar-nav button {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #374151;
  font-size: 14px;
  font-family: 'Merriweather', serif;
  transition: background-color 0.2s, color 0.2s;
}

.sidebar-nav button:hover {
  background-color: #F3F4F6;
  color: #1D3557;
}

.sidebar-nav button.active-tab {
  background-color: #F3F4F6;
  color: #1D3557;
  font-weight: 500;
}

/* Mobile menu styles */
@media (max-width: 768px) {
  .header-dashboard {
    height: var(--mobile-header-height);
  }

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 10px;
  }

  .sidebar-nav {
    transform: translateX(-100%);
    width: 250px;
    position: fixed;
    top: var(--mobile-header-height);
    left: 0;
    height: calc(100vh - var(--mobile-header-height));
    background-color: white;
    border-right: 1px solid #E5E7EB;
    padding: 0;
    z-index: 999;
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
  }

  .nav-section {
    padding-top: 20px;
  }

  .section-header {
    padding: 10px 20px;
    color: #6B7280;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Merriweather', serif;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .sidebar-nav button {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    color: #374151;
    font-size: 14px;
    font-family: 'Merriweather', serif;
    transition: background-color 0.2s, color 0.2s;
  }

  .sidebar-nav.active {
    transform: translateX(0);
  }

  .overlay {
    position: fixed;
    top: var(--mobile-header-height);
    left: 0;
    width: 100%;
    height: calc(100vh - var(--mobile-header-height));
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
    display: none;
  }

  .overlay.active {
    display: block;
  }

  .main-content {
    margin-left: 0;
    padding-top: calc(var(--mobile-header-height) + 20px);
  }
}

/* ==========================================================================
     Overlay (for mobile menu)
     ========================================================================== */
.overlay {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
.coming-soon-banner {
  position: absolute;
  left: 44px;
  margin-top: 5px;
  font-size: 12px;
  background-color: #ffcc00;
  padding: 2px 6px;
  border-radius: 4px;
  color: #000;
}
@media (max-width: 468px) {
  .coming-soon-banner {
    font-size: 10px;
    left: 125px;
    margin-top: -17px;
  }
}

/* ==========================================================================
     Main Content
     ========================================================================== */
.main-content {
  flex: 1;
  margin-left: 220px; /* Sidebar width + padding */
  padding: 6vw 2vw;
  background-color: white;
}

/* Add styles for the page title */
.main-content h1,
.main-content h2,
.main-content h3 {
  font-family: 'Merriweather', serif;
  font-size: 24px;
  color: #1D3557;
  margin-bottom: 30px;
  font-weight: 600;
}

/* For mobile responsiveness */
@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    min-height: calc(100vh - 100px - 120px);
    box-sizing: border-box;
  }
  
  .main-content h1,
  .main-content h2,
  .main-content h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

/* ==========================================================================
     Dashboard Search & Filters
     ========================================================================== */
.dashboard-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
}
.filters {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.filters label {
  margin-right: 20px;
  color: #666;
}

/* ==========================================================================
     Credits Section
     ========================================================================== */
.no-credits-message {
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  color: black;
}
.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2em 0;
}
.header-title {
  font-size: 25px;
  font-weight: 600;
  color: black;
  font-family: "Inter", sans-serif;
  text-transform: capitalize;
}
.credits-info {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  margin-left: 7.5px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.credits-display-container {
  display: inline-flex;
  justify-content: flex-end;
  border: 1px solid #d1d1d1;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  float: right;
  margin-top: 0;
}
.dashboard-disclaimer {
  text-align: center;
  color: gray;
  font-size: 18px;
  font-family: "Inter";
}
.wallet-icon {
  margin-right: 10px;
  font-size: 24px;
  cursor: pointer;
}

/* ==========================================================================
     Social Share Buttons
     ========================================================================== */
.reddit-share-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 15px auto;
}
.reddit-share-button {
  cursor: pointer;
}
.twitter-share-button {
  cursor: pointer;
  background-color: black;
  border-radius: 50px;
  padding: 10px;
}
.reddit-share-container span {
  margin-right: 8px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.tooltipshare {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  pointer-events: none;
  z-index: 1000;
}

/* ==========================================================================
     Animation & Stage Image
     ========================================================================== */
.animation-container-dashboard {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  margin-top: 20px;
  padding: 20px 0;
  max-width: 100%;
}
.stage-image-dashboard {
  width: 150px;
  height: 70px;
  margin-bottom: 10px;
}

/* ==========================================================================
     Trending Section
     ========================================================================== */
.trending-section {
  margin-top: 40px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05); */
}
.trending-info {
  display: flex;
  justify-content: space-between;
}
.trending-summary {
  background-color: #139c8d;
  color: white;
  padding: 20px;
  border-radius: 8px;
}
.stock-info {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
}
.stock-info h2 {
  margin: 0;
}
.stock-details p {
  margin-top: 10px;
  color: #333;
}

/* ==========================================================================
     Predictions Section & Progress Bar
     ========================================================================== */
.predictions-section {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f5f5f5;
  height: 150px;
  justify-content: center;
}
.prediction-data h4 {
  text-align: justify;
  text-align-last: left;
  justify-content: center;
  padding-left: 30px;
  padding-right: 700px;
}
.progress-bar-container {
  margin-top: 20px;
  width: 80%;
  background-color: #e0e0e0;
  border-radius: 10px;
  height: 25px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.progress-bar {
  background-color: #139c8d;
  height: 100%;
  transition: width 0.5s ease-in-out;
  border-radius: 10px;
  position: relative; /* needed to position the inner text */
}

/* Position the progress text in the center of the bar */
.progress-bar .progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: whitesmoke; /* neutral color for contrast on both white or green backgrounds */
  font-size: 14px;
  font-weight: bold;
}

.progress-bar-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  text-align: center;
}

/* ==========================================================================
     Blur Effects
     ========================================================================== */
.blur-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.prediction-data.blurred {
  filter: blur(2px);
  pointer-events: none;
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
}
.blurred {
  filter: blur(4px);
}

/* ==========================================================================
     Overlay for Sign-in Content
     ========================================================================== */
.overlay-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  text-align: center;
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  border: 2px solid #139c8d;
  margin: 0 auto;
}

.sign-up-text {
  margin-bottom: 20px;
}

.sign-up-text p {
  font-style: normal;
  font-size: 16px;
  margin-bottom: 15px;
  color: #333;
}

.sign-up-text-ps {
  font-size: 14px;
  color: #666;
  margin-top: 15px;
  text-align: center;
}

.blurred-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1001;
}

.clear-text {
  z-index: 1002;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin: 20px 0;
}

.sign-in-button {
  margin: 20px auto;
  padding: 12px 24px;
  border: 2px solid #1d3557;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease;
  display: inline-block;
  min-width: 200px;
}

.sign-in-button:hover {
  background-color: #1d3557;
  color: white;
}

@media (max-width: 768px) {
  .overlay-content {
    width: 85%;
    padding: 25px;
    top: 50%;
    max-height: 90vh;
    overflow-y: auto;
  }

  .sign-up-text p {
    font-size: 15px;
  }

  .sign-up-text-ps {
    font-size: 13px;
  }

  .sign-in-button {
    width: 100%;
    max-width: 250px;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .overlay-content {
    width: 90%;
    padding: 20px;
    top: 50%;
  }

  .sign-up-text p {
    font-size: 14px;
  }

  .clear-text {
    font-size: 16px;
  }
}

/* ==========================================================================
     Tooltip
     ========================================================================== */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: not-allowed;
}
.tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* ==========================================================================
     Stock Container
     ========================================================================== */
.stock-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  background-color: #1d3557;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); */
  max-width: 1300px;
  margin: 0 auto;
  font-family: Merriweather;
}
.stock-container h2 {
  font-family: Merriweather;
  color: white;
  margin-bottom: 1em;
}
.title-container {
  text-align: center;
  margin-bottom: 5px;
  color: white;
}
.stock-grid {
  display: flex;
  justify-content: center;
  width: 100%;
}
.title-container {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  font-size: 18px;
  color: black;
  display: flex;
  flex-direction: column;
}
.positive-change {
  color: green;
}
.negative-change {
  color: red;
}
.side-by-side {
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
}
.stock-grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.percentage {
  font-size: 1.2em;
  font-weight: bold;
}
.stock-grid-item p {
  margin: 5px 0 0;
  font-size: 0.9em;
  color: white;
}

/* ==========================================================================
     Footer Section
     ========================================================================== */
.logged-in-page .footer-section {
  margin-top: auto; /* Pushes footer to bottom */
  margin-left: 220px; /* Sidebar width */
  width: calc(100% - 220px);
  background-color: #1d3557;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1em;
  box-sizing: border-box;
  z-index: 999;
  /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); */
  transition: height 0.3s ease, width 0.3s ease, left 0.3s ease;
  overflow-y: auto;
  font-family: "Inter";
}
.logged-in-page .footer-section .disclaimer-button {
  background-color: none;
  border: none;
  color: #139c8d;
  cursor: pointer;
  font-size: 1em;
  text-decoration: none;
  text-align: center;
  align-self: center;
  font-family: Merriweather;
  padding-left: 50vw;
}
.logged-in-page .footer-section > footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.logged-in-page .footer-section .footer-top,
.logged-in-page .footer-section .footer-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.logged-in-page .footer-section .footer-column {
  flex: 1 1 200px;
  margin: 0.5em;
  font-size: 1em;
}
.logged-in-page .footer-section .footer-column h3,
.logged-in-page .footer-section .footer-column li,
.logged-in-page .footer-section .footer-call-to-action button {
  font-size: 0.9em;
}
.logged-in-page .footer-section .footer-logo-signup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.logged-in-page .footer-section .footer-logo {
  text-align: left;
}
.logged-in-page .footer-section .footer-call-to-action {
  text-align: right;
}
.logged-in-page .footer-section .footer-signup {
  padding: 8px 16px;
  font-size: 0.9em;
  margin-top: 0;
  font-family: "Inter";
}
.logged-in-page .footer-section .footer-bottom-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
.logged-in-page .footer-section .footer-social {
  margin: 5px;
  justify-content: flex-start;
}
.logged-in-page .footer-section .footer-links-row {
  flex: 1 1 auto;
  justify-content: flex-start;
  font-family: "Inter" !important;
}
.logged-in-page .footer-section .footer-separator {
  margin: 0;
}
.confidence-level {
  width: 100px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  background: linear-gradient(to right, #fd2506, #ffcf01, #1ba741);
}
.confidence-triangle-low {
  color: black;
  left: -90px;
  position: relative;
}
.confidence-triangle-medium {
  color: black;
  left: -57px;
  position: relative;
}
.confidence-triangle-high {
  color: black;
  left: -24px;
  position: relative;
}

/* ==========================================================================
     Dropdown Menu for User
     ========================================================================== */
.user-dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: #139c8d;
  font-family: Merriweather;
  background-color: white;
  min-width: 50px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1002;
  padding: 5px 0;
  animation: fadeIn 0.3s ease-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}
.dropdown-menu .logout-button {
  width: 100%;
  padding: 10px 15px;
  background-color: #fff;
  color: #139c8d;
  font-family: Merriweather;
  border: none;
  border-radius: 0 0 4px 4px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.dropdown-menu .logout-button:hover {
  background-color: #fff;
}

/* ==========================================================================
     Hidden Tabs for Anonymous Users
     ========================================================================== */
.hidden-tabs-anon {
  display: flex;
  flex-direction: column;
}

/* ==========================================================================
     MEDIA QUERIES (Max–width in Descending Order)
     ========================================================================== */

/* 1. max-width: 1500px */
@media (max-width: 1500px) {
  .prediction-output-container {
    width: 90%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    text-align: center;
  }
}

/* 2. max-width: 1024px */
@media (max-width: 1024px) {
  .logged-in-page .footer-section {
    padding: 1em;
  }
  .logged-in-page .footer-section .footer-column {
    flex: 1 1 150px;
    font-size: 0.85em;
  }
  .logged-in-page .footer-section .footer-signup {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}

/* 3. max-width: 991px */
@media (max-width: 991px) {
  .animation-container-dashboard {
    margin-bottom: 80px;
    z-index: 15;
    position: relative;
    padding: 15px 0;
  }
  .stage-image-dashboard {
    width: 120px;
    height: auto;
  }
  .wallet-icon {
    font-size: 24px;
    margin-top: 2.3vw;
    cursor: pointer;
  }
  .credits-info span {
    font-size: 3vw;
    white-space: nowrap;
    width: max-content;
  }
  .credits-info p {
    font-size: 3vw;
    white-space: nowrap;
  }
}

/* 4. max-width: 786px */
@media (max-width: 786px) {
  .credits-display-container {
    padding: 10px;
  }
  .header-container {
    flex-direction: row;
    padding: 0 15px;
    margin-top: 4em;
  }
  .credits-info {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .credits-info span {
    font-size: 12px;
  }
  .wallet-icon:hover + .credits-info {
    display: flex;
  }
  .credits-info {
    display: flex;
  }
  .logged-in-page .footer-section {
    position: relative;
    z-index: auto;
    bottom: 0;
    transform-origin: bottom;
  }
}

/* 5. max-width: 768px */
@media (max-width: 768px) {
  .hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* width: 6.5vw;
      height: 6.5vw; */
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 10px;
  }
  .hamburger-menu span {
    height: 3px;
  }
  .sidebar-nav {
    position: fixed;
    top: 60px;
    left: 0;
    width: 250px;
    background-color: white;
    height: calc(100% - 60px);
    padding-top: 20px;
    padding-left: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1001;
  }
  .sidebar-nav.active {
    transform: translateX(0);
  }
  .overlay {
    display: block;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  .header-left {
    /* margin-left: 10px; */
    flex: 1;
  }
  .main-content {
    margin-left: 0;
    min-height: calc(100vh - 100px - 120px);
    box-sizing: border-box;
  }
  .sidebar-nav button {
    padding: 10px 15px;
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid #e0e0e0;
  }
  .sidebar-nav button:last-child {
    border-bottom: none;
  }
  .stock-grid {
    flex-direction: column;
    align-items: center;
  }
  .side-by-side {
    flex-direction: column;
    align-items: center;
  }
  .stock-grid-item {
    margin-bottom: 10px;
  }
  .prediction-data h4 {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1em;
  }
  .overlay-content {
    width: 82%;
  }
  .dashboard-search-container {
    width: 100%; /* Increase overall width to 90% of the viewport */
    max-width: 500px; /* Optionally set a max-width for very large phones/tablets */
    margin: 0 auto; /* Center the container */
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    justify-content: center;
  }

  /* Ensure the search input box spans the full width of its container */
  .dashboard-search-container .search-box {
    width: 100%;
    margin-bottom: 10px;
    width: 700px;
    /* padding-right: 10px; */
  }
  .dashboard-search-container .landing-ticker-input {
    width: 180px;
    height: 30px;
  }

  .dashboard-search-container .landing-search-button {
    width: 100px;
    height: 30px;
  }

  /* Set the analyze button to be full width and keep a consistent height */
  .dashboard-search-container .analyze-button {
    width: 100%;
    height: 150px; /* Force the same height as in mobile view */
    /* padding: 10px 0; */
    font-size: 16px;
    margin-bottom: 5px;
  }
  .dashboard-disclaimer {
    text-align: center;
    color: gray;
    font-size: 14px;
  }
  .animation-container-dashboard {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    margin-bottom: 200px;
    z-index: auto;
  }
  .stage-image-dashboard {
    width: 100px;
    height: auto;
    margin-bottom: -85px;
  }
  .logout-button {
    padding: 5px 10px;
    font-size: 12px;
  }
  /* Optional: Grid adjustments for stock container on mobile */
  .stock-container .stock-grid-item:nth-child(5) {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    color: white;
  }
  .stock-container .stock-grid-item:nth-child(2) {
    grid-column: 3 / 5;
    grid-row: 2 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-left: 120px;
    background-color: #1d3557;
    border-radius: 5px;
    color: white;
  }
  .stock-container .stock-grid-item:nth-child(6) {
    grid-column: 2 / 4;
    grid-row: 3 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    color: white;
  }
  .stock-container .stock-grid-item:nth-child(3) {
    grid-column: 3 / 5;
    grid-row: 3 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    padding-left: 50px;
    background-color: #1d3557;
    border-radius: 5px;
    color: white;
  }
  .stock-container .stock-grid-item:nth-child(4) {
    grid-column: 4 / 4;
    grid-row: 3 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #1d3557;
    border-radius: 5px;
    color: white;
  }
}

/* 6. max-width: 480px */
@media (max-width: 480px) {
  .header-left h3 {
    font-size: 1em;
  }
  .sidebar-nav button {
    font-size: 14px;
    padding: 10px 5px;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  /* .hamburger-menu {
      width: 6.5vw;
      height: 6.5vw;
    } */
  .hamburger-menu span {
    height: 2px;
  }
  .overlay-content {
    width: 90%;
  }
  .animation-container-dashboard {
    margin-bottom: 120px;
    padding: 15px;
    height: 250px;
  }
  .stage-image-dashboard {
    width: 90px;
    height: auto;
  }

  .progress-bar-container {
    width: 90%;
    height: 30px; /* slightly taller on mobile for better touch and readability */
    margin: 10 auto;
  }

  .progress-bar .progress-text {
    font-size: 16px; /* increase font-size for mobile if desired */
  }

  .prediction-output-container {
    width: 95%;
    padding: 0 5px;
    margin: 0 auto;
  }
  .logged-in-page .footer-section {
    position: relative;
    z-index: 9;
    bottom: 0;
    transform-origin: bottom;
  }
  .dashboard-disclaimer {
    text-align: center;
    color: gray;
    font-size: 14px;
  }

  .dashboard-search-container .landing-ticker-input {
    width: 180px;
    height: 40px;
    padding-right: 0px;
  }

  .dashboard-search-container .landing-search-button {
    width: 100px;
    height: 40px;
  }
  /* Reset the overall footer container so it spans the full viewport width */
  .logged-in-page .footer-section {
    margin-left: 0; /* Remove the sidebar offset */
    width: 100%; /* Full width for mobile */
    padding: 1em; /* Maintain padding */
    box-sizing: border-box;
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: center;
    justify-content: center;
    /* You can remove or adjust the box-shadow if needed */
    box-shadow: none;
    transition: none;
  }

  /* Footer main content: if you wrap inner footer content in a <footer> tag */
  .logged-in-page .footer-section > footer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  /* Footer Top and Bottom Row adjustments */
  .logged-in-page .footer-top,
  .logged-in-page .footer-bottom {
    display: flex;
    flex-direction: row; /* Keep items in a row */
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow wrapping */
  }

  /* Align logo and button in a row */
  .logged-in-page .footer-logo-signup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  /* Footer columns: make them full-width and centered */
  .logged-in-page .footer-section .footer-column {
    flex: 1 1 100%;
    text-align: center;
    margin: 0.5em 0;
    font-size: 1em;
  }

  /* Footer logo and sign-up area: stack logo and call-to-action vertically */
  /* Align logo and button in a row */
  .logged-in-page .footer-section .footer-logo-signup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  /* Left-align logo */
  .logged-in-page .footer-logo {
    text-align: left;
  }

  /* Right-align call-to-action button */
  .logged-in-page .footer-call-to-action {
    text-align: right;
  }

  /* Adjust the footer-bottom row */
  .logged-in-page .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  /* Align footer links and social icons side by side */
  .logged-in-page .footer-bottom-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  /* Footer Social Icons */
  .logged-in-page .footer-social {
    margin: 5px;
    justify-content: flex-start;
  }

  /* Footer Links Row */
  .logged-in-page .footer-links-row {
    justify-content: center;
    font-size: small;
  }

  /* Adjust text size for smaller screens */
  .logged-in-page .footer-section h3,
  .logged-in-page .footer-section li,
  .logged-in-page .footer-section button {
    font-size: 0.9em;
    justify-content: left;
    display: flex;
  }

  .dashboard-disclaimer {
    font-size: 14px;
    text-align: center;
  }
}

/* 7. max-width: 468px */
@media (max-width: 468px) {
  .logged-in-page .footer-section {
    position: relative;
    z-index: 9;
    bottom: 0;
    transform-origin: bottom;
  }
  .dashboard-disclaimer {
    text-align: center;
    color: gray;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
  }

  .logged-in-page .footer-section {
    width: 100%;
    text-align: center;
    padding: 1em;
    position: relative;
    bottom: 0;
    transform-origin: bottom;
  }

  /* Footer Top and Bottom Row adjustments */
  .logged-in-page .footer-top,
  .logged-in-page .footer-bottom {
    display: flex;
    flex-direction: row; /* Keep items in a row */
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow wrapping */
  }

  /* Align logo and button in a row */
  .logged-in-page .footer-logo-signup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  /* Left-align logo */
  .logged-in-page .footer-logo {
    text-align: left;
  }

  /* Right-align call-to-action button */
  .logged-in-page .footer-call-to-action {
    text-align: right;
  }

  /* Adjust the footer-bottom row */
  .logged-in-page .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  /* Align footer links and social icons side by side */
  .logged-in-page .footer-bottom-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  /* Footer Social Icons */
  .logged-in-page .footer-social {
    margin: 5px;
    justify-content: flex-start;
  }

  /* Footer Links Row */
  .logged-in-page .footer-links-row {
    justify-content: center;
    font-size: small;
  }

  /* Adjust text size for smaller screens */
  .logged-in-page .footer-section h3,
  .logged-in-page .footer-section li,
  .logged-in-page .footer-section button {
    font-size: 0.9em;
  }
}

/* Example CSS adjustments */
.menu {
  margin-bottom: 0; /* Remove bottom margin */
}

.analysis {
  margin-top: 0; /* Remove top margin */
  padding-top: 0; /* Remove top padding */
}

.dashboard {
  font-family: 'Merriweather', serif; /* Apply globally */
}

.dashboard h3 {
  font-family: 'Merriweather', serif; /* Apply Merriweather font */
}

/* Add any other specific styles here */

/* Header height variable for consistency */
:root {
  --header-height: 80px;
  --mobile-header-height: 80px;
}
