/* Container for the trending page */
.trending-page-container {
  width: 100%;
  margin: 0 auto;
  /* font-family: IBM Plex Sans; */
}

.min-headline {
  font-size: 1.5em;
  text-align: left;
  color: #333;
  margin-bottom: 10px;
  padding-left: 20px;
}

.trending-container {
  padding: 50px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header row for trending section, including search bar and dropdown */
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.box-headline {
  font-size: 1.8em;
  color: #333;
}

.right-side-controls {
  display: flex;
  align-items: center;
  font-size: 14px;
}

/* Search bar */
.search-bar {
  padding: 10px;
  font-size: 0.9em;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  outline: none;
  margin-right: 10px;
}

.search-bar:focus {
  border-color: #54a2f0;
}

/* Dropdown styling */
.trending-container .sort-dropdown {
  padding: 10px;
  font-size: 1em;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  background-color: #f9fbff;
  font-weight: bold;
  color: #3d3c42;
}

.sort-dropdown:focus {
  border-color: #54a2f0;
}

/* Styling for the trending table */
.trending-table-container {
  overflow-x: auto; /* Allows horizontal scrolling on smaller screens */
  margin-top: 20px;
}

.trending-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders don't overlap */
  margin-bottom: 20px;
  background-color: white; /* Set table background to white */
}

.trending-table th,
.trending-table td {
  padding: 22px 25px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 0.9em;
}

.trending-table th {
  color: #1d3557; /* Light gray color for the headings */
  font-weight: bold;
}

.trending-table td {
  padding: 20px;
}

.trending-table tr {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.trending-table tr:hover {
  background-color: #f1f1f1;
}

/* Pagination section */
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9em;
}

.pagination span {
  font-size: 14px;
  color: #555;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.page-btn {
  padding: 8px 16px;
  margin: 0 5px;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.page-btn:hover {
  background-color: #ddd;
}

.page-btn.active {
  color: #888;
}

.page-btn:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.view-prediction {
  border: none;
  background-color: transparent;
  color: #888;
  text-decoration: none;
  padding: 7px 14px;
  border-radius: 5px;
  font-family: Arial;
  font-weight: 700;
  font-size: 0.9em;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.trending-disclaimer {
  text-align: center;
  color: gray;
  font-size: 14px;
}

.view-prediction:hover {
  color: #1d3557;
  cursor: pointer;
}

@media (max-width: 768px) {
  .box-headline {
    font-size: 1.5em;
  }

  .trending-container {
    padding: 15px;
  }
}

.trending-page-container .pagination span {
  font-size: 1em;
}

@media (max-width: 768px) {
  .trending-page-container .pagination span {
    font-size: 0.8em;
  }
}
