/* Apply box-sizing to all elements */
* {
  box-sizing: border-box;
}

/* General Styles */
.footer-section {
  padding: 20px;
  overflow-x: hidden; /* Prevents horizontal overflow */
  font-family: 'Inter';
}

/* Top Row */
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-logo img {
  max-height: 50px;
}

.footer-call-to-action {
  display: flex;
  align-items: flex-end;
}

.footer-signup {
  background-color: #139C8D;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
  cursor: pointer;
}

.footer-signup:hover {
  background-color: #107f6f;
}

/* Separator Line */
.footer-separator {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #777;
}

/* Bottom Row */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; /* For responsiveness */
}

/* Footer Columns */
.footer-column {
  flex: 1;
  min-width: 150px;
  margin-right: 20px;
  text-align: left;
}

/* Make the first column twice the width */
.footer-column:first-child {
  flex: 2;
}

.footer-column h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column li {
  margin-bottom: 8px;
}

.footer-column a,
.footer-column li {
  text-decoration: none;
  color: inherit;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.footer-column a:hover,
.footer-column li:hover {
  text-decoration: underline;
}

.email {
  font-weight: lighter;
}

.email-ids {
  font-weight: normal;
}

/* Social Media Links */
.footer-social {
  margin-top: 20px;
}

.footer-social .social-icon {
  font-size: 24px;
  color: #000;
  margin-right: 15px;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

/* Footer Section */
.footer-section {
  background-color: #1D3557;
  padding: 60px 40px; /* Increased padding for more space around the footer */
  padding-bottom: 30px;
  color: #F0F0F0;
  font-family: 'Inter', sans-serif;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%; /* Prevents overflow beyond the screen */
  margin: 0 auto;
  color: #F0F0F0;
  gap: 40px; /* Space between the columns */
}

.footer-logo img {
  height: 50px;
  margin-bottom: 20px;
}

.footer-links {
  display: flex;
  gap: 70px; /* Increased gap between the footer columns */
}

.footer-column h3 {
  font-size: 16px;
  margin-bottom: 15px; /* Increased space between the heading and links */
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column li {
  margin-bottom: 12px; /* Increased space between the list items */
  font-size: 14px;
}

.footer-call-to-action {
  text-align: right;
}

.footer-call-to-action p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px; /* Space between the text and the button */
}

.footer-signup {
  background-color: #139C8D;
  color: white;
  padding: 12px 24px; /* Larger padding for the signup button */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
  font-family: 'Inter';
}

.footer-signup:hover {
  background-color: #139C8D;
  box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.2);
}

.footer-social {
  display: flex;
  justify-content: flex-end;
  gap: 30px; /* Increased gap between the social media icons */
}

.social-icon {
  font-size: 24px; /* Slightly larger font size for the icons */
  color: #000;
  text-decoration: none;
}

.social-icon i {
  color: #ffffff; /* White color for the actual Font Awesome icons */
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.social-icon i:hover {
  color: #139C8D; /* Change color on hover */
}

.disclaimer {
  align-content: left;
  font-size: normal;
}

/* Footer Links Row */
.footer-links-row {
  display: flex;
  justify-content: center; /* Default is center */
  align-items: center;
  margin-top: 5px; /* Adjust spacing as needed */
}

.footer-link {
  color: #fff;
  text-decoration: none;
  font-family: 'Inter';
  font-weight: 400;
  margin: 0 10px; /* Adjust spacing between links */
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.footer-link:hover {
  text-decoration: underline;
  color: #117a7a; /* Optional hover color */
}

.footer-link-separator {
  color: #fff;
}

/* Disclaimer Button Styling */
.disclaimer-button {
  background-color: transparent;
  border: none;
  color: #fff; /* Matching the sign-up button color */
  cursor: pointer;
  font-size: 1em;
  text-decoration: none;
  text-align: left;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.disclaimer-button:hover {
  color: #117a7a;
}

/* Popup Overlay Styling */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* min-height: 100vh; */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it stays on top */
  animation: fadeIn 0.3s ease;
}

/* Popup Content Styling */
.popup-content {
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  text-align: justify;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  position: relative;
  animation: slideIn 0.3s ease;
}

.popup h2 {
  text-align: center;
}

/* Popup Header */
.popup-content h2 {
  margin-bottom: 15px;
  color: #139C8D;
}

/* Popup Paragraph */
.popup-content p {
  margin-bottom: 20px;
  line-height: 1.6;
  color: #333;
}

/* Close Button Styling */
.close-button {
  padding: 10px 20px;
  background-color: #139C8D;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.close-button:hover {
  background-color: #117a7a;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.disclaimer-link {
  color: #fff;
  text-decoration: none;
  font-weight: lighter;
}

/* --- Place media queries after all general styles --- */

/* Responsive Design */

/* Tablet View */
@media (max-width: 1024px) {
  .footer-links-row {
    justify-content: flex-start; /* Change to flex-start on tablets */
  }
  #disclaimer {
    margin-left: 0px;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-call-to-action {
    align-items: flex-start;
    margin-top: 10px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-column {
    margin-bottom: 0px;
  }

  .footer-social {
    margin-top: 20px;
  }

  .footer-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px; /* Space between sections on mobile */
    width: 100%; /* Added to prevent overflow */
    box-sizing: border-box; /* Ensure padding and gap are included in width */
  }

  .footer-links {
    flex-direction: column;
    gap: 30px; /* Space between footer columns on mobile */
    text-align: left;
  }

  /* .footer-signup {
    margin-bottom: 20px; Extra margin at the bottom of the button
  } */

  .popup-content {
    padding: 20px 25px;
  }

  .disclaimer-button {
    text-align: left;
    font-size: 0.9em;
    padding-left: 0px;
  }

  .disclaimer {
    text-align: left;
  }

  .close-button {
    width: 100%;
  }

  /* Adjust .footer-links-row */
  .footer-links-row {
    justify-content: flex-start; /* Change to flex-start on mobile */
  }
}

/* Small Mobile View */
@media (max-width: 480px) {
  .popup-content {
    max-height: 95vh; /* Sets max-height to 95% on very small screens */
    padding: 15px 10px; /* Reduces padding to maximize content area */
  }

  .close-button {
    padding: 8px 16px; /* Slightly reduces button size for very small screens */
    font-size: 0.9em; /* Slightly reduces font size for better fit */
  }

  /* Adjust .footer-links-row */
  .footer-links-row {
    justify-content: flex-start; /* Ensure flex-start on small screens */
  }
}

/* Disclaimer Popup Enhancements */
.popup-content {
  max-height: 80vh; /* Limits the height to 80% of the viewport height */
  overflow-y: auto; /* Adds a vertical scrollbar when content exceeds max-height */
}

/* Ensure popup-content is the containing block for absolutely positioned elements */
.popup-content {
  position: relative; /* Already set, but reinforcing for clarity */
}

/* Close Button Styling */
.close-button {
  position: absolute; /* Positions the button relative to popup-content */
  top: 15px; /* Distance from the top of the popup */
  right: 15px; /* Distance from the right of the popup */
  padding: 8px 16px; /* Adequate padding for touch accessibility */
  background-color: #139C8D; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Removes default border */
  border-radius: 5px; /* Rounded corners for aesthetics */
  cursor: pointer; /* Changes cursor to pointer on hover */
  z-index: 1001; /* Ensures the button appears above other elements */
  font-size: 1em; /* Readable font size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Adds subtle shadow */
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

/* Close Button Hover Effect */
.close-button:hover {
  background-color: #117a7a; /* Darkens the button on hover for feedback */
}

/* Mobile Adjustments for Tablet Devices */
@media (max-width: 768px) {
  .popup-content {
    max-height: 90vh; /* Increases max-height to 90% on tablets and smaller devices */
    padding: 20px 15px; /* Adjusts padding for better spacing on mobile */
  }

  /* Ensure the close button remains at the top-right */
  .close-button {
    top: 10px; /* Slightly adjust position for smaller screens */
    right: 10px; /* Slightly adjust position for smaller screens */
    padding: 10px 20px; /* Increase padding for better touch accessibility */
    font-size: 1em; /* Ensure readable font size */
  }
  #disclaimer {
    margin-left: 0px;
  }
}

/* Further Mobile Adjustments for Small Screens */
@media (max-width: 480px) {
  .popup-content {
    max-height: 95vh; /* Sets max-height to 95% on very small screens */
    padding: 15px 10px; /* Reduces padding to maximize content area */
  }

  .close-button {
    top: 8px; /* Further adjust position for very small screens */
    right: 8px; /* Further adjust position for very small screens */
    padding: 8px 16px; /* Slightly reduce padding */
    font-size: 0.9em; /* Slightly reduce font size for better fit */
  }

  #disclaimer {
    margin-left: 0px;
  }
}

/* Optional: Ensure Smooth Scrolling Inside Popup */
.popup-content::-webkit-scrollbar {
  width: 8px;
}

.popup-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.footer-logo-signup{
  display: flex;
  justify-content: space-between;
  
}

.footer-call-to-action{
  flex-wrap: wrap;
}

@media (max-width: 768px) {
    .logged-in-page .footer-call-to-action {
        text-align: right;
    }
}
@media (max-width: 768px) {
    .logged-in-page .footer-call-to-action {
        text-align: right;
    }
}
@media (max-width: 768px) {
    .logged-in-page .footer-call-to-action {
        margin-bottom: 0px;
        margin-top: 0px;
        padding-left: 0px;
        flex-wrap: wrap;
    }
}
.footer-call-to-action {
    flex-wrap: wrap;
}
@media (max-width: 768px) {
    .footer-call-to-action {
        align-items: flex-start;
        margin-top: 10px;
    }
}
.footer-call-to-action {
    text-align: right;
}
.footer-call-to-action {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.footer-logo-signup{
  width: 100%;
  margin-bottom: 20px;
}

.footer-call-to-action{
  align-items: flex-end;
}

.footer-section {
  margin-top: auto; /* Pushes the footer to the bottom of the page */
  width: 100%; /* Ensure it takes full width */
}

/* Apply to all <a> links */
a {
  cursor: pointer; /* Fallback to pointer */
}

#faq-page a{
  font-weight: bold;
}
