/* TopPredictionsPage.css */

.top-predictions-page-container {
  font-family: 'Merriweather', serif;
  width: 82%;
  margin: 0 auto;
  /* font-family: IBM Plex Sans; */
}

.min-headline {
  font-size: 1.5em;
  text-align: left;
  color: #333;
  margin-bottom: 10px;
  padding-left: 20px;
}

.top-predictions-container {
  padding: 50px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.box-headline {
  font-size: 1.8em;
  color: #333;
}

.right-side-controls {
  display: flex;
  align-items: center;
}

.search-bar {
  padding: 10px;
  font-size: 0.9em;
  /* border: 1px solid #ccc; */
  border: none;
  border-radius: 5px;
  outline: none;
  margin-right: 10px;
  background-color: #F9FBFF;
}

.search-bar:focus {
  border-color: #54a2f0;
}

.sort-dropdown {
  padding: 10px;
  font-size: 0.7em;
  /* border: 1px solid #ccc; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  background-color: #F9FBFF;
  font-weight: bold;
  color: #3D3C42;
}

.sort-dropdown:focus {
  border-color: #54a2f0;
}

.top-predictions-table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.top-predictions-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: white;
  table-layout: fixed; /* Ensure equal width columns */
}

.top-predictions-table th,
.top-predictions-table td {
  padding: 22px 25px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 0.9em;
}

.top-predictions-table th {
  color: #bbb;
  font-weight: bold;
  
}

.top-predictions-table td {
  word-wrap: break-word;
}

.top-predictions-table tr  {
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.top-predictions-table tr:hover {
  background-color: #f1f1f1;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9em;
}

.pagination span {
  font-size: 0.5em;
  color: #555;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.page-btn {
  padding: 8px 16px;
  margin: 0 5px;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}

.page-btn:hover {
  background-color: #ddd;
}

.page-btn.active {
  color: #888;
}

.page-btn:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

@media (max-width: 768px) {

  .box-headline{
    font-size: 1.5em;
    padding-right: 0.15em;
  }

  .sort-dropdown{
    font-size: 0.75em;
    margin-left: 0.1em;
  }
  .top-predictions-container {
    padding: 22px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .top-predictions-table th,
  .top-predictions-table td {
    padding: 12px 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    font-size: 0.9em;
  }

}

